import {
    commonStoreFetch, commonStoreSave,
} from "@/store/common-functions";

function getDefaultState() {
    return {}
}

const getters = {}

const actions = {
    getLabel({commit}, code) {
        return commonStoreFetch(commit, '/barcode/scan/' + code)
    },
    getBarCode({commit}, {id, type}) {
        return commonStoreFetch(commit, '/barcode/' + type + '/' + id)
    },
    getOffering({commit}, code) {
        return commonStoreFetch(commit, '/barcode/scan-show/' + code)
    },
    labelPrinted(_, code) {
        return commonStoreSave('/barcode/printed/' + code, {}, true)
    },
}

const mutations = {}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
import {commonStoreSave} from "@/store/common-functions";

function getDefaultState() {
    return {
        warehouse: null,
        warehouses: [],
    }
}

const getters = {
    getWarehouse: state => state.warehouse,
    getWarehouses: state => state.warehouses,
}

const actions = {
    fetchWarehouses({commit}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/warehouses', {'skip_loading': true})
                .then(response => {
                    commit('setWarehouses', response.data)
                    resolve(response)
                })
        })
    },
    fetchWarehouse({commit}, id) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/warehouses/' + (id ? id : 0), {'skip_loading': true})
                .then(response => {
                    commit('setWarehouse', {...response.data, id})
                    resolve(response)
                })
        })
    },
    saveWarehouse({commit}, warehouse) {
        return new Promise((resolve, reject) => {
            window.axios.post(window.apiUrl + '/warehouses/' + (warehouse.id ? warehouse.id : 0), warehouse, {'skip_loading': true})
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
    syncWarehouseQty(_, id) {
        return commonStoreSave('/warehouses/sync/' + id, {})
    },
    deleteWarehouse({dispatch}, id) {
        return new Promise((resolve) => {
            window.axios.delete(window.apiUrl + '/warehouses/' + id).then((response) => {
                resolve(response);
            })
        })
    },
    regenerateBarcodesWarehouse({dispatch}, id) {
        return new Promise((resolve) => {
            window.axios.post(window.apiUrl + '/warehouses/regeneratebarcodes/' + id).then((response) => {
                resolve(response);
            })
        })
    },

}

const mutations = {
    setWarehouse(state, response) {
        state.warehouse = response.warehouse
    },
    setWarehouses(state, warehouses) {
        state.warehouses = warehouses.warehouses
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
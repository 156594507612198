import {commonStoreFetch, commonStoreSave, commonTableFetch} from "@/store/common-functions";

function getDefaultState() {
    return {
        jobsTable: {},
        failedJobsTable: {},
        offeringJobsTable: {},
        jobs_count: 0,
        notifications: {},
        done_jobs: 0,
        impersonate: false,
        external_connections: [],
        failed_jobs: [],
        first_failed_group: null,
        jobsByGroup: {},
        jobs_info: {},
        user_jobs: {},
    }
}

const getters = {
    getJobsTable: state => state.jobsTable,
    getFailedJobsTable: state => state.failedJobsTable,
    getOfferingJobsTable: state => state.offeringJobsTable,
    getJobsCount: state => state.jobs_count,
    getFailedJobs: state => state.failed_jobs,
    getDoneJobs: state => state.done_jobs,
    getJobsByGroup: state => state.jobsByGroup,
    getFailedGroup: state => state.first_failed_group,
    getJobsInfo: state => state.jobs_info,
    getImpersonate: state => state.impersonate,
    getExternalConnections: state => state.external_connections,
    getNotifications: state => state.notifications,
    getUserJobs: state => state.user_jobs,
}

const actions = {
    fetchJobs({commit, _}, params) {
        return commonTableFetch(commit, '/jobs', 'setJobsTable', params)
    },
    fetchFailedJobs({commit, _}, params) {
        return commonTableFetch(commit, '/failed-jobs', 'setFailedJobsTable', params)
    },
    fetchOfferingJobs({commit, _}, params) {
        return commonTableFetch(commit, '/offering-jobs', 'setOfferingJobsTable', params)
    },
    fetchJobsStatus({commit}) {
        return commonStoreFetch(commit, '/jobs-status', 'setJobsStatus', true)
    },
    fetchUserJobsStatus({commit}) {
        return commonStoreFetch(commit, '/user-jobs-status', 'setUserJobsStatus', true)
    },
    fetchJobInfo({commit}, jobGroup) {
        return commonStoreFetch(commit, '/jobs/' + jobGroup, 'setJobsInfo')
    },
    fetchJobsByGroupStatus({commit}, jobGroup) {
        return commonStoreFetch(commit, '/jobs-by-group-status?job_group=' + jobGroup, 'setJobsByGroupStatus', true)
    },
    markAsShown({commit, _}, ids) {
        return commonStoreSave('/jobs-status', {ids})
    },
    enableConnections({commit, _}, ids) {
        return commonStoreSave('/enable-connections', {})
    }
}

const mutations = {
    setJobsTable(state, data) {
        state.jobsTable = data
    },
    setFailedJobsTable(state, data) {
        state.failedJobsTable = data
    },
    setOfferingJobsTable(state, data) {
        state.offeringJobsTable = data
    },
    setJobsStatus(state, commonDataJobs) {
        state.jobs_count = commonDataJobs.jobs
        state.failed_jobs = commonDataJobs.failed_jobs
        state.done_jobs = commonDataJobs.done_jobs
        state.first_failed_group = commonDataJobs.first_failed_group
        state.impersonate = commonDataJobs.impersonate
        state.external_connections = commonDataJobs.external_connections
        state.notifications = commonDataJobs.notifications
    },
    setUserJobsStatus(state, commonDataJobs) {
        state.user_jobs = commonDataJobs.user_jobs
    },
    setJobsInfo(state, data) {
        state.jobs_info = data
    },
    setJobsByGroupStatus(state, commonDataJobs) {
        state.jobsByGroup = commonDataJobs
    }
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
import {commonStoreDelete, commonStoreFetch, commonStoreSave, commonTableFetch} from "@/store/common-functions";

function getDefaultState() {
    return {
        attribute: null,
        attributes: [],
        attributesTree: [],
        attributeSet: null,
        attributeSets: [],
        attributeSetsWithAttributes: [],
        setAttributes: [],
        optionGroup: null,
        optionGroups: [],
        attributeSetTable: {},
        attributeTable: {},
        optionGroupsTable: {},
    }
}

const getters = {
    getAttribute: state => state.attribute,
    getAttributes: state => state.attributes,
    getAttributesTree: state => state.attributesTree,
    getAttributeSet: state => state.attributeSet,
    getAttributeSets: state => state.attributeSets,
    getSetAttributes: state => state.setAttributes,
    getAttributeOptionGroup: state => state.optionGroup,
    getAttributeOptionGroups: state => state.optionGroups,
    getAttributeSetsWithAttributes: state => state.attributeSetsWithAttributes,
    getAttributeSetsTable: state => state.attributeSetTable,
    getAttributeTable: state => state.attributeTable,
    getAttributeOptionGroupsTable: state => state.optionGroupsTable,
}

const actions = {
    fetchAttributes({commit}) {
        return commonStoreFetch(commit, '/attributes', 'setAttributes', true)
    },
    fetchAttributeTable({commit, _}, params) {
        return commonTableFetch(commit, '/attributes-table', 'setAttributeTable', params)
    },
    fetchAttributeOptionGroups({commit}) {
        return commonStoreFetch(commit, '/attributes-option-groups', 'setAttributeOptionGroups', true)
    },
    fetchAttributeOptionGroupsTable({commit, _}, params) {
        return commonTableFetch(commit, '/attributes-option-groups-table', 'setAttributeOptionGroupsTable', params)
    },
    fetchAttributeSets({commit}) {
        return commonStoreFetch(commit, '/attribute-sets', 'setAttributeSets', true)
    },
    fetchAttributeSetsTable({commit, _}, params) {
        return commonTableFetch(commit, '/attribute-sets-table', 'setAttributeSetsTable', params)
    },
    fetchAttributeSetsWithAttributes({commit}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/attribute-sets/with-attributes', {'skip_loading': true})
                .then(response => {
                    commit('setAttributeSetsWithAttributes', response.data)
                    resolve(response)
                })
        })
    },
    fetchSetAttributes({commit}, id) {
        return commonStoreFetch(commit, '/attribute-sets/' + id + '/attributes', 'setSetAttributes', true)
    },
    fetchAttributesTree({commit}) {
        return commonStoreFetch(commit, '/attributestree', 'setAttributesTree', true)
    },
    fetchAttribute({commit}, id) {
        return commonStoreFetch(commit, '/attributes/' + (id ? id : 0), 'setAttribute', true)
    },
    fetchAttributeOptionGroup({commit}, id) {
        return commonStoreFetch(commit, '/attributes-option-groups/' + (id ? id : 0), 'setAttributeOptionGroup', true)
    },
    fetchAttributeSet({commit}, id) {
        return commonStoreFetch(commit, '/attribute-sets/' + (id ? id : 0), 'setAttributeSet', true)
    },
    saveAttribute({commit}, attribute) {
        return commonStoreSave('/attributes/' + (attribute.id ? attribute.id : 0), attribute)
    },
    saveAttributeOptionGroup({commit}, group) {
        return commonStoreSave('/attributes-option-groups/' + (group.id ? group.id : 0), group)
    },
    saveAttributeSet({commit}, attribute) {
        return commonStoreSave('/attribute-sets/' + (attribute.id ? attribute.id : 0), attribute)
    },
    deleteAttribute({dispatch}, id) {
        return commonStoreDelete('/attributes/' + id)
    },
    deleteAttributeOptionGroup({dispatch}, id) {
        return commonStoreDelete('/attributes-option-groups/' + id)
    },
    deleteAttributeSet({dispatch}, id) {
        return commonStoreDelete('/attribute-sets/' + id)
    },
}

const mutations = {
    setAttribute(state, response) {
        state.attribute = response.attribute
    },
    setAttributes(state, attributes) {
        state.attributes = attributes.attributes
    },
    setAttributeOptionGroups(state, attributes) {
        state.optionGroups = attributes.option_groups
    },
    setAttributeOptionGroupsTable(state, data) {
        state.optionGroupsTable = data
    },
    setAttributeOptionGroup(state, attributes) {
        state.optionGroup = attributes.option_group
    },
    setAttributeSet(state, response) {
        state.attributeSet = response.set
    },
    setAttributeSets(state, attributes) {
        state.attributeSets = attributes.sets
    },
    setAttributeSetsTable(state, data) {
        state.attributeSetTable = data
    },
    setAttributeTable(state, data) {
        state.attributeTable = data
    },
    setAttributesTree(state, attributes) {
        state.attributesTree = attributes.attributestree
    },
    setSetAttributes(state, attributes) {
        state.setAttributes = attributes
    },
    setAttributeSetsWithAttributes(state, response) {
        state.attributeSetsWithAttributes = response
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
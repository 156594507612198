function getDefaultState() {
    return {
        actions: [],
        actions_with_id: [],
        sub_actions: [],
        action: {},
        sub_action: {},
    }
}

const getters = {
    getActions: state => state.actions,
    getAction: state => state.action,
    getSubActions: state => state.sub_actions,
    getSubAction: state => state.sub_action,
    getActionsWithId: state => state.actions_with_id,
}

const actions = {
    fetchActions({commit}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/todos-action', {'skip_loading': true})
                .then(response => {
                    commit('setActions', response.data)
                    resolve(response)
                })
        })
    },
    fetchSubActions({commit}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/todos-sub-action', {'skip_loading': true})
                .then(response => {
                    commit('setSubActions', response.data)
                    resolve(response)
                })
        })
    },
    fetchAction({commit}, id) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/todos-action/' + (id ? id : 0), {'skip_loading': true})
                .then(response => {
                    commit('setAction', response.data)
                    resolve(response)
                })
        })
    },
    fetchSubAction({commit}, id) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/todos-sub-action/' + (id ? id : 0), {'skip_loading': true})
                .then(response => {
                    commit('setSubAction', response.data)
                    resolve(response)
                })
        })
    },
    deleteAction({dispatch}, id) {
        return new Promise((resolve) => {
            window.axios.delete(window.apiUrl + '/todos-action/' + id).then((response) => {
                resolve(response);
            })
        })
    },
    deleteSubAction({dispatch}, id) {
        return new Promise((resolve) => {
            window.axios.delete(window.apiUrl + '/todos-sub-action/' + id).then((response) => {
                resolve(response);
            })
        })
    },
    saveAction({commit}, action) {
        return new Promise((resolve, reject) => {
            window.axios.post(window.apiUrl + '/todos-action', action, {'skip_loading': true})
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
    saveSubAction({commit}, action) {
        return new Promise((resolve, reject) => {
            window.axios.post(window.apiUrl + '/todos-sub-action', action, {'skip_loading': true})
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
}

const mutations = {
    setActions(state, response) {
        state.actions = response.actions
    },
    setAction(state, response) {
        state.action = response.action
    },
    setSubActions(state, response) {
        state.sub_actions = response.sub_actions
    },
    setSubAction(state, response) {
        state.sub_action = response.sub_action
        state.actions_with_id = response.actions
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
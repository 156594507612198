<template>
    <validation-provider :name="name" :rules="validate" v-slot="validationContext">
        <b-form-group>
            <template v-if="!disableLabel" v-slot:label>
                <template v-if="label">{{ label }}</template>
                <template v-else-if="labelHtml"><span v-html="labelHtml"></span></template>
                <template v-else>
                    {{ $t(labelPrefix + name) }}
                </template>
                <span v-if="validationContext.required" class="text-danger">*</span>

                <b-button variant="info"
                          v-if="help"
                          v-b-popover.hover.top="help"
                >
                    <font-awesome-icon icon="info"/>
                </b-button>
            </template>
            <b-input-group>
                <b-input-group-prepend v-if="icon" is-text>
                    <font-awesome-icon :icon="icon"/>
                </b-input-group-prepend>
                <b-form-input
                    v-model="inputVal"
                    :placeholder="placeholder"
                    :state="getValidationState(validationContext)"
                    v-bind="$props"
                    :readonly="readonly"
                    :autocomplete="autocomplete"
                    v-on="$listeners"
                    :size="size"
                    :class="inputClass"
                    :type="getType"
                    :maxLength="maxLength"
                    :name="getName"
                ></b-form-input>
                <b-input-group-append v-if="type=='password'">
                    <b-button @click="togglePassword">
                        <font-awesome-icon icon="eye"/>
                    </b-button>
                </b-input-group-append>
                <slot name="append-slot"/>
            </b-input-group>
            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
    </validation-provider>
</template>

<script>
import validateMixin from '../mixins/validate'

export default {
    props: {
        'name': {
            type: String,
            required: true
        },
        inputClass: {
            type: String,
            default: ''
        },
        'value': [String, Number],
        'validate': [String, Object],
        'icon': [String, Array],
        'type': String,
        'min': [String, Number],
        'maxLength': [String, Number],
        'step': [String, Number],
        'label': String,
        'labelHtml': String,
        'placeholder': String,
        'help': String,
        'size': {
            type: String,
            default: 'lg'
        },
        'disableLabel': {
            type: Boolean,
            default: false
        },
        'readonly': {
            type: Boolean,
            default: false
        },
        'autocomplete': {
            type: String,
            default: 'new-password'
        },
        'labelPrefix': {
            type: String,
            default: 'common.label.'
        },
    },
    data() {
        return {
            showPassword: false,
        }
    },
    mixins: [validateMixin],
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
        getType() {
            return this.type == 'password' && this.showPassword ? 'text' : this.type
        },
        getName() {
            if (this.name == 'street') {
                return 's_t_r_e_e_t'
            }
            if (this.name == 'offering.email') {
                return 'e_m_a_i_l'
            }
            return this.name
        }
    },
    methods: {
        togglePassword() {
            this.showPassword = !this.showPassword;
        }
    }
}
</script>
<template>
    <validation-provider :name="name" :rules="validate" v-slot="validationContext">
        <b-form-group>
            <template v-slot:label>
                <template v-if="label">{{ label }}</template>
                <template v-else>
                    {{ $t(labelPrefix + name) }}
                </template>
                <span v-if="validationContext.required" class="text-danger">*</span>
            </template>
            <b-input-group>
                <b-input-group-prepend v-if="icon" is-text>
                    <font-awesome-icon :icon="icon"/>
                </b-input-group-prepend>
                <div v-if="inputVal">
                    <a v-if="typeof inputVal == 'string'"
                       @click.prevent="fileUrl(inputVal)"
                       href="#"
                    >{{ inputVal }}</a>
                    <div v-else>{{ inputVal.name }}</div>
                    <b-button
                        size="sm"
                        variant="danger"
                        @click="removeFile"
                        class="ml-2"
                        :title="$t('common.title.delete')"
                    >
                        <font-awesome-icon icon="trash"/>
                    </b-button>
                </div>
                <b-form-file v-else
                             v-model="inputVal"
                             :placeholder="placeholder"
                             :state="getValidationState(validationContext)"
                             :accept="accept"
                             v-bind="$props"
                             :readonly="readonly"
                             @input="callback"
                ></b-form-file>
            </b-input-group>
            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
    </validation-provider>
</template>

<script>
import validateMixin from '../mixins/validate'

export default {
    props: {
        'name': {
            type: String,
            required: true
        },
        'value': [String, Number, File],
        'validate': [String, Object],
        'icon': [String, Array],
        'type': String,
        'accept': String,
        'label': String,
        'placeholder': String,
        'callback': [Function],
        'readonly': {
            type: Boolean,
            default: false
        },
        'labelPrefix': {
            type: String,
            default: 'common.label.'
        },
        'documentType': {
            type: String,
            required: true
        },
    },
    mixins: [validateMixin],
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        }
    },
    methods: {
        fileUrl(name) {
            return this.$root.openDocument(name,this.documentType)
        },
        removeFile() {
            this.inputVal = null
            this.$emit('input', null);
        }
    }
}
</script>
import {buildFormData} from "@/store/common-functions";
import {commonTableFetch} from "@/store/common-functions";

function getDefaultState() {
    return {
        profile: null,
        profiles: [],
        profilesList: [],
        profilesTable: {},
    }
}

const getters = {
    getProfile: state => state.profile,
    getProfiles: state => state.profiles,
    getProfilesTable: state => state.profilesTable,
    getProfilesList: state => state.profilesList,
}

const actions = {
    fetchProfiles({commit, _}, params) {
        return commonTableFetch(commit, '/profiles', 'setProfilesTable', params)
    },
    fetchProfile({commit}, id) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/profiles/' + (id ? id : 0), {'skip_loading': true})
                .then(response => {
                    commit('setProfile', {...response.data, id})
                    resolve(response)
                })
        })
    },
    saveProfile({commit}, profile) {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            buildFormData(formData, profile);

            window.axios.post(window.apiUrl + '/profiles/' + (profile.id ? profile.id : 0), formData, {'skip_loading': true})
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
    deleteProfile({dispatch}, id) {
        return new Promise((resolve) => {
            window.axios.delete(window.apiUrl + '/profiles/' + id).then((response) => {
                resolve(response);
            })
        })
    },
    fetchProfilesList({commit, _}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/profiles/list', {'skip_loading': true})
                .then(response => {
                    commit('setProfilesList', response.data)
                    resolve()
                })
        })
    },
}

const mutations = {
    setProfile(state, response) {
        state.profile = response.profile
    },
    setProfilesTable(state, data) {
        state.profilesTable = data
    },
    setProfilesList(state, profilesList) {
        state.profilesList = profilesList
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
import {commonStoreSave} from "@/store/common-functions";

function getDefaultState() {
    return {
        source: {},
        sources: [],
    }
}

const getters = {
    getSource: state => state.source,
    getSources: state => state.sources,
}

const actions = {
    fetchSource({commit, _}, id) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/channel/' + id)
                .then(response => {
                    commit('setSource', response.data)
                    resolve()
                })
        })
    },
    fetchSources({commit, _}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/channel')
                .then(response => {
                    commit('setSources', response.data)
                    resolve()
                })
        })
    },
    testChannel({commit, _}, id) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/channel/' + id + '/test', {'skip_loading': true})
                .then(response => {
                    resolve(response)
                })
        })
    },
    deleteSource({dispatch}, id) {
        return new Promise((resolve) => {
            window.axios.delete(window.apiUrl + '/channel/' + id).then((response) => {
                resolve(response);
            })
        })
    },
    deleteStatus({dispatch, commit}, data) {
        return new Promise((resolve) => {
            window.axios.delete(window.apiUrl + '/channel/' + data.id + '/status/' + data.status).then((response) => {
                commit('setSource', response.data)
                resolve(response);
            })
        })
    },
    syncSource({commit}, data) {
        return commonStoreSave('/channel/sync/' + data.source, data)
    },
    saveSource({commit}, channel) {
        return new Promise((resolve, reject) => {
            window.axios.post(window.apiUrl + '/channel/' + (channel.id ? channel.id : 0), channel, {'skip_loading': true})
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
    updateSource({commit}, channel) {
        return new Promise((resolve, reject) => {
            window.axios.post(window.apiUrl + '/channel/update/' + channel.id, channel, {'skip_loading': true})
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
    saveStatus({commit}, data) {
        return new Promise((resolve, reject) => {
            window.axios.post(window.apiUrl + '/channel/' + data.id + '/status', data, {'skip_loading': true})
                .then(response => {
                    commit('setSource', response.data)
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
    saveStatusMap({commit}, data) {
        return new Promise((resolve, reject) => {
            window.axios.post(window.apiUrl + '/channel/' + data.id + '/status-map', data, {'skip_loading': true})
                .then(response => {
                    commit('setSource', response.data)
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
}

const mutations = {
    setSource(state, data) {
        state.source = data.source
    },
    setSources(state, data) {
        state.sources = data
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
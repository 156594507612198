import {commonTableFetch} from "@/store/common-functions";

function getDefaultState() {
    return {
        hotel: {},
        stars: [],
        positions: [],
        hotelsList: [],
        hotelsCities: [],
        hotelsTable: []
    }
}

const getters = {
    getHotel: state => state.hotel,
    getStars: state => state.stars,
    getPositions: state => state.positions,
    getHotelsList: state => state.hotelsList,
    getHotelsCities: state => state.hotelsCities,
    getHotelsTable: state => state.hotelsTable,
}

const actions = {
    fetchHotels({commit, _}, params) {
        return commonTableFetch(commit, '/hotels', 'setHotelsTable', params)
    },
    fetchHotelsList({commit, _}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl
                + '/hotels/list'
            ).then(response => {
                commit('setHotelsList', response.data)
                resolve()
            })
        })
    },
    fetchHotelsCities({commit, _}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl
                + '/hotels/cities'
            ).then(response => {
                commit('setHotelsCities', response.data)
                resolve()
            })
        })
    },
    fetchHotel({commit}, {id}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/hotels/' + id, {'skip_loading': true})
                .then(response => {
                    commit('setHotel', response.data)
                    resolve(response)
                })
        })
    },
    deleteHotel({dispatch}, id) {
        return new Promise((resolve) => {
            window.axios.delete(window.apiUrl + '/hotels/' + id).then((response) => {
                resolve(response);
            })
        })
    },
    saveHotel({commit}, hotel) {
        return new Promise((resolve, reject) => {
            window.axios.post(window.apiUrl + '/hotels/' + (hotel.id ? hotel.id : 0), hotel, {'skip_loading': true})
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
}

const mutations = {
    setHotelsTable(state, data) {
        state.hotelsTable = data
    },
    setHotel(state, data) {
        state.hotel = data.hotel
        state.stars = data.stars
        state.positions = data.positions
    },
    setHotelsList(state, data) {
        state.hotelsList = data.hotels
    },
    setHotelsCities(state, data) {
        state.hotelsCities = data.cities
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
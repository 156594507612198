import {commonStoreFetch, commonTableFetch} from "@/store/common-functions";

function getDefaultState() {
    return {
        logsTable: {},
        log: null,
    }
}

const getters = {
    getApiLogsTable: state => state.logsTable,
    getApiLog: state => state.log,
}

const actions = {
    fetchApiLogs({commit, _}, params) {
        return commonTableFetch(commit, '/api-logs', 'setLogsTable', params)
    },
    fetchApiLog({commit, _}, id) {
        return commonStoreFetch(commit, '/api-logs/' + id, 'setLog')
    },
}

const mutations = {
    setLogsTable(state, data) {
        state.logsTable = data
    },
    setLog(state, data) {
        state.log = data.data
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
import {commonTableFetch} from "@/store/common-functions";

function getDefaultState() {
    return {
        header: null,
        headers: [],
        headersTable: {},
    }
}

const getters = {
    getHeader: state => state.header,
    getHeaders: state => state.headers,
    getHeadersTable: state => state.headersTable,
}

const actions = {
    fetchHeaders({commit, _}, params) {
        return commonTableFetch(commit, '/headers', 'setHeadersTable', params)
    },
    fetchHeader({commit}, id) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/headers/' + (id ? id : 0), {'skip_loading': true})
                .then(response => {
                    commit('setHeader', {...response.data, id})
                    resolve(response)
                })
        })
    },
    saveHeader({commit}, header) {
        return new Promise((resolve, reject) => {
            window.axios.post(window.apiUrl + '/headers/' + (header.id ? header.id : 0), header, {'skip_loading': true})
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
    deleteHeader({dispatch}, id) {
        return new Promise((resolve) => {
            window.axios.delete(window.apiUrl + '/headers/' + id).then((response) => {
                resolve(response);
            })
        })
    },
}

const mutations = {
    setHeader(state, response) {
        state.header = response.header
    },
    setHeaders(state, headers) {
        state.headers = headers.headers
    },
    setHeadersTable(state, data) {
        state.headersTable = data
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
import {commonStoreSave, commonTableFetch} from "@/store/common-functions";

function getDefaultState() {
    return {
        warehouses: [],
        stocksTable: {},
        stockLogsTable: {},
        stocks: [],
        stock: {},
        shelve: {},
    }
}

const getters = {
    getWarehouses: state => state.warehouses,
    getStocksTable: state => state.stocksTable,
    getStockLogsTable: state => state.stockLogsTable,
    getStocks: state => state.stocks,
    getStock: state => state.stock,
    getShelve: state => state.shelve,
}

const actions = {
    fetchShelve({commit}, id) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/stock/shelve/' + (id ? id : 0), {'skip_loading': true})
                .then(response => {
                    commit('setShelve', response.data)
                    resolve(response)
                })
        })
    },
    saveShelve({commit}, shelve) {
        return new Promise((resolve, reject) => {
            window.axios.post(window.apiUrl + '/stock/shelve/' + (shelve.id ? shelve.id : 0), shelve, {'skip_loading': true})
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
    generateRacks({commit}, data) {
        return new Promise((resolve, reject) => {
            window.axios.post(window.apiUrl + '/stock/rack/' + data.id, data, {'skip_loading': true})
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
    generateShelves({commit}, data) {
        return new Promise((resolve, reject) => {
            window.axios.post(window.apiUrl + '/stock/shelve/' + data.warehouseId + '/' + data.rackNum, data, {'skip_loading': true})
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
    fetchWarehouses({commit}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/stock/warehouses', {'skip_loading': true})
                .then(response => {
                    commit('setWarehouses', response.data)
                    resolve(response)
                })
        })
    },
    fetchRacks({commit}, {warehouseId}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/stock/racks/' + warehouseId, {'skip_loading': true})
                .then(response => {
                    resolve(response.data.racks)
                })
        })
    },
    fetchShelves({commit}, {warehouseId, rackId, articleId}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/stock/free-shelves/' + warehouseId + '/' + rackId + '/' + articleId, {'skip_loading': true})
                .then(response => {
                    resolve(response.data.shelves)
                })
        })
    },
    fetchRacksAndShelves({commit}, {warehouseId}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/stock/racks-and-shelves/' + warehouseId, {'skip_loading': true})
                .then(response => {
                    resolve(response.data.data)
                })
        })
    },
    fetchStocks({commit, _}, params) {
        return commonTableFetch(commit, '/stocks/' + params.articleId, 'setStocksTable', params)
    },
    fetchStockLogs({commit, _}, params) {
        return commonTableFetch(commit, '/stock-logs/' + params.articleId, 'setStockLogsTable', params)
    },
    fetchStockList({commit, _}, articleId) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/stocks/list/' + articleId, {'skip_loading': true})
                .then(response => {
                    commit('setStocks', response.data)
                    resolve(response)
                })
        })
    },
    fetchStock({commit}, id) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/stock/' + (id ? id : 0), {'skip_loading': true})
                .then(response => {
                    commit('setStock', response.data)
                    resolve(response)
                })
        })
    },
    saveStock({commit}, stock) {
        return new Promise((resolve, reject) => {
            window.axios.post(window.apiUrl + '/stock', stock, {'skip_loading': true})
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
    massAssign({commit}, data) {
        return commonStoreSave('/stock/mass-assign', data, false)
    },
    move({commit}, data) {
        return new Promise((resolve, reject) => {
            window.axios.post(window.apiUrl + '/stock/move/', data, {'skip_loading': true})
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
    deleteFrom({commit}, data) {
        return new Promise((resolve, reject) => {
            window.axios.post(window.apiUrl + '/stock/delete-from/', data, {'skip_loading': true})
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
    returnDefect({commit}, data) {
        return commonStoreSave('/stock/return-defect', data)
    },
    deleteStock({dispatch}, id) {
        return new Promise((resolve) => {
            window.axios.delete(window.apiUrl + '/stock/' + id).then((response) => {
                resolve(response);
            })
        })
    },
    deleteShelve({dispatch}, id) {
        return new Promise((resolve) => {
            window.axios.delete(window.apiUrl + '/stock/shelve/' + id).then((response) => {
                resolve(response);
            })
        })
    },
    deleteRack({dispatch}, {warehouseId, rackNum}) {
        return new Promise((resolve) => {
            window.axios.delete(window.apiUrl + '/stock/rack/' + warehouseId + '/' + rackNum).then((response) => {
                resolve(response);
            })
        })
    },
}

const mutations = {
    setWarehouses(state, response) {
        state.warehouses = response.warehouses
    },
    setStocksTable(state, data) {
        state.stocksTable = data
    },
    setStockLogsTable(state, data) {
        state.stockLogsTable = data
    },
    setStocks(state, data) {
        state.stocks = data
    },
    setStock(state, response) {
        state.stock = response.stock
    },
    setShelve(state, response) {
        state.shelve = response.shelve
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
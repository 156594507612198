import {commonTableFetch} from "@/store/common-functions";

function getDefaultState() {
    return {
        insurance: {},
        insurancesTable: [],
    }
}

const getters = {
    getInsurance: state => state.insurance,
    getInsurancesTable: state => state.insurancesTable,
}

const actions = {
    fetchInsurances({commit, _}, params) {
        return commonTableFetch(commit, '/insurance', 'setInsurancesTable', params)
    },
    fetchInsurance({commit}, {id}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/insurance/' + id, {'skip_loading': true})
                .then(response => {
                    commit('setInsurance', response.data)
                    resolve(response)
                })
        })
    },
    saveInsurance({commit}, insurance) {
        return new Promise((resolve, reject) => {
            const formData = new FormData();

            Object.entries(insurance).forEach(([key, value]) => {
                formData.append(key, value);
            });

            window.axios.post(window.apiUrl + '/insurance/' + insurance.position_id, formData, {
                'skip_loading': true,
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            })
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
}

const mutations = {
    setInsurancesTable(state, data) {
        state.insurancesTable = data
    },
    setInsurance(state, data) {
        state.insurance = data.insurance
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
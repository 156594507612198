<template>
    <a href="#" @click="back" class="btn btn-sm btn-primary float-right">Back</a>
</template>

<script>

export default {
    name: 'BackButton',
    data() {
        return {}
    },
    props: {
        'backRoute': {
            default: '/settings/global',
        },
    },
    methods: {
        back() {
            this.$router.push({path: this.backRoute}).catch(() => {
            })
        },
    }
}
</script>
function getDefaultState() {
    return {
        cotraveler: {
            id: null,
            prefix: 'herr',
            first_name: '',
            last_name: '',
            sex: 'male',
            birthday: '',
            zip: '',
            city: '',
            street: '',
            country: 'Deutschland',
            email: '',
            mobile: '',
            phone: '',
            allergic: 0,
            diabetic: 0,
            additional: '',
            comment: '',
            contact_prefix: '',
            contact_first_name: '',
            contact_last_name: '',
            contact_phone: '',
            status: 'Nein',
        },
        cotravelersTable: {},
    }
}

const getters = {
    getCotraveler: state => state.cotraveler,
    getCotravelersTable: state => state.cotravelersTable,
}

const actions = {
    fetchCotraveler({commit}, id) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/cotravelers/' + (id ? id : 0), {'skip_loading': true})
                .then(response => {
                    commit('setCotraveler', {...response.data.cotraveler, id})
                    resolve(response)
                })
        })
    },
    saveCotraveler({commit}, cotraveler) {
        return new Promise((resolve) => {
            if (cotraveler.id) {
                window.axios.put(window.apiUrl + '/cotravelers/' + cotraveler.id, cotraveler, {'skip_loading': true})
                    .then(response => {
                        commit('setCotraveler', response.data.cotraveler)
                        resolve(response);
                    })
            } else {
                window.axios.put(window.apiUrl + '/cotravelers/0', cotraveler, {'skip_loading': true}).then(response => {
                    commit('setCotraveler', response.data)
                    resolve(response);
                })
            }
        })
    },
    deleteCotraveler({dispatch}, {cotravelerId}) {
        return new Promise((resolve) => {
            window.axios.delete(window.apiUrl + '/cotravelers/' + cotravelerId).then((response) => {
                resolve(response);
            })
        })
    },
    fetchCotravelers({commit, _}, params) {
        return new Promise((resolve) => {
            let queryString = Object.keys(params.filter).map((key) => {
                if (Array.isArray(params.filter[key])) {
                    return params.filter[key].map(option => {
                        return 'filter[' + encodeURIComponent(key) + '][]=' + option.id
                    }).join('&')
                } else {
                    return 'filter[' + encodeURIComponent(key) + ']=' + encodeURIComponent(params.filter[key])
                }
            }).join('&');
            window.axios.get(window.apiUrl
                + '/cotravelers?page=' + params.currentPage
                + '&sorting_by=' + params.sortBy
                + '&sorting_desc=' + params.sortDesc
                + '&per_page=' + params.perPage
                + '&' + queryString
            ).then(response => {
                commit('setCotravelersTable', response.data)
                resolve()
            })
        })
    },
}

const mutations = {
    setCotraveler(state, cotraveler) {
        state.cotraveler = {...getDefaultState().cotraveler, ...cotraveler}
    },
    setCotravelersTable(state, data) {
        state.cotravelersTable = data
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
<template>
    <draggable :class="{[`cursor-grabbing`]: dragging === true} && {[`download`]: !preview}"
               class="row files-wrapper pb-3 mb-3"
               v-model="files"
               group="files"
               @start="dragging = true"
               @end="dragging = false"
               tag="div"
               handle=".image-preview"
    >
        <b-col v-for="(media, index) in files" v-if="media.dont_show != true"
               :key="index"
               :cols="cols"
               class="image-preview"
        >
            <div class="background"
                 :data-preview="getImageBlobUrl(media.id, media.type)"
                 :style="(preview && previewImages[media.type+'_'+media.id]) ? 'background-image: url('+previewImages[media.type+'_'+media.id]+')' : ''"
            >
                <font-awesome-icon class="file-type"
                                   v-if="fileIcons[media.name.split('.').pop()]"
                                   size="4x"
                                   :icon="fileIcons[media.name.split('.').pop()]"
                ></font-awesome-icon>

                <div v-if="rename && editing.includes(media.id)" class="editblock">
                    <input type="text" class="input_media"
                           :value="media.name.replace(/^.*[\\\/]/, '')"
                           @change="event => mediaRename(media, event)"
                           :disabled="loading == 1"
                    />
                </div>

                <a v-else target="_blank" :href="mediaUrl(media.id,media.type)">{{
                        media.name.replace(/^.*[\\\/]/, '')
                    }}</a>


                <div class="remove" v-if="!readonly">
                    <b-button variant="danger" @click="removeFile(media.id)" :title="$t('common.title.delete')">
                        <font-awesome-icon icon="trash"></font-awesome-icon>
                    </b-button>
                </div>

                <div v-if="editing.includes(media.id)" class="save">
                    <b-button variant="info" @click="saveFile(media)">
                        <font-awesome-icon icon="save"></font-awesome-icon>
                    </b-button>
                </div>
                <div v-else-if="rename" class="edit">
                    <b-button variant="info"
                              @click="editFile(media.id)"
                              :title="$t('common.title.edit')"
                    >
                        <font-awesome-icon icon="edit"></font-awesome-icon>
                    </b-button>
                </div>
            </div>
        </b-col>
        <b-col :cols="cols" v-if="!readonly">
            <vue-dropzone ref="dz"
                          id="customdropzone"
                          :options="dropzoneOptions"
                          :include-styling="false"
                          @vdropzone-success="uploaded"
                          @vdropzone-error="errorUpload"
                          @vdropzone-error-multiple="errorUpload"
                          @vdropzone-sending="sendingEvent"
                          use-custom-slot
            >
                <div>
                    <font-awesome-icon icon="upload" size="2x"></font-awesome-icon>
                </div>
                <div>{{ $t('common.button.media_upload') }}</div>
            </vue-dropzone>
        </b-col>
    </draggable>

</template>

<script>
import Draggable from "vuedraggable"
import axios from "axios";

export default {
    name: 'ZwMedia',
    components: {
        Draggable
    },
    props: {
        'value': [String, Array],
        'downloadUrl': {
            type: String,
            required: false
        },
        'acceptedFiles': {
            type: String,
        },
        'id': {
            type: [String, Number],
        },
        'type': {
            type: String,
            default: () => ''
        },
        'api_url': {
            type: String,
            default: () => ''
        },
        'media_url': {
            type: String,
            default: () => ''
        },
        'api_token': {
            type: String,
            default: () => ''
        },
        'api_user_token': {
            type: String,
            default: () => ''
        },
        'cols': {
            type: Number,
            default: () => 2,
        },
        'parent_id': {
            type: [String, Number, Array],
            default: () => ''
        },
        'preview': {
            type: Boolean,
            default: () => false
        },
        'rename': {
            type: Boolean,
            default: () => false
        },
        'readonly': {
            type: Boolean,
            default: () => false
        },
    },
    data() {
        return {
            dropzoneOptions: {
                url: (this.api_url ? this.api_url : window.apiUrl) + '/media/upload',
                maxFilesize: 5,
                previewTemplate: this.template(),
                acceptedFiles: this.acceptedFiles,
                headers: {
                    Authorization: `Bearer ${this.api_token ? this.api_token : window.apiToken}`,
                    UserAuthToken: `${this.api_user_token ? this.api_user_token : window.apiUserToken}`
                },
            },
            dragging: false,
            loading: false,
            files: [],
            display_files: [],
            editing: [],
            previewImages: {},
            callPreviewImages: {},
            fileIcons: {
                'pdf': 'file-pdf',
                'docx': 'file-word',
                'doc': 'file-word',
                'csv': 'file-csv',
                'png': 'file-image',
                'jpg': 'file-image',
                'gif': 'file-image',
                'jpeg': 'file-image',
                'webp': 'file-image',
                'txt': 'file-lines',
            }
        }
    },
    methods: {
        mediaRename(media, event) {
            media.new_name = event.target.value;
        },
        mediaUrl(id, type) {
            const routeData = this.$router.resolve({
                name: 'Open',
                params: {type: 'media', name: id, media_type: type}
            });
            return this.media_url ? window.apiUrl + this.media_url + '/' + id + '/' + type : routeData.href
        },
        getImageBlobUrl(name, type) {
            if (typeof this.previewImages[type + '_' + name] == 'undefined' && typeof this.callPreviewImages[type + '_' + name] == 'undefined') {
                this.$set(this.callPreviewImages, type + '_' + name, 'call')
                axios.get((this.api_url ? this.api_url : window.apiUrl) + '/preview/media/' + name + '/' + type,
                    {
                        'headers': {
                            Authorization: `Bearer ${this.api_token ? this.api_token : window.apiToken}`,
                            UserAuthToken: `${this.api_user_token ? this.api_user_token : window.apiUserToken}`
                        },
                        'skip_loading': true,
                    })
                    .then(response => {
                        function s2ab(s) {
                            let buf = new ArrayBuffer(s.length);
                            let view = new Uint8Array(buf);
                            for (let i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
                            return buf;
                        }

                        const blob = new Blob([s2ab(atob(response.data.content))], {type: response.data.type});

                        this.$set(this.previewImages, type + '_' + name, window.URL.createObjectURL(blob))
                    })
            }
        },
        uploaded(file, response) {
            if (!this.files) {
                this.files = []
            }

            response.forEach((element) => {
                this.files.push({
                    id: element.id,
                    dont_show: element.dont_show,
                    num: element.num,
                    type: element.type,
                    name: element.name,
                })

                this.$emit('input', this.files);
            })
        },
        errorUpload(file, message, xhr) {
            console.log(file, message, xhr)
            this.$root.showMessage(
                this.$t('common.form.title.error'),
                message.message ? message.message : (message ?? 'Unknown error'),
                'danger'
            )
        },
        editFile(media_id) {
            this.editing.push(media_id);
        },
        saveFile(media) {
            if (typeof media.new_name == "undefined") {
                this.editing = this.editing.filter(function (e) {
                    return e !== media.id
                })
                return false;
            }

            const formData = new FormData();
            formData.append('name', media.new_name);
            formData.append('id', media.id);
            formData.append('type', media.type);

            this.loading = true;
            window.axios.post((this.api_url ? this.api_url : window.apiUrl) + '/media/rename', formData, {
                'skip_loading': true,
                'headers': {
                    Authorization: `Bearer ${this.api_token ? this.api_token : window.apiToken}`,
                    UserAuthToken: `${this.api_user_token ? this.api_user_token : window.apiUserToken}`
                }
            })
                .then(response => {
                    Object.entries(this.files).forEach(([key, value]) => {
                        if (response.data.id == value.id) {
                            this.files[key].name = response.data.name;
                        }
                    });
                })
                .finally(() => {
                    this.loading = false
                    this.editing = this.editing.filter(function (e) {
                        return e !== media.id
                    })
                })
        },
        removeFile(media_id) {
            this.files = this.files.filter(media => {
                return media.id != media_id
            })
            this.$emit('input', this.files);
        },
        template: function () {
            return `<div></div>`;
        },
        sendingEvent(file, xhr, formData) {
            formData.append('type', this.type);
            formData.append('parent_id', this.parent_id);
        }
    },
    mounted() {
        this.files = this.value
    },
    watch: {
        'value': {
            handler: function () {
                this.files = this.value
            },
            deep: true
        }
    },
}
</script>

<style>
.files-wrapper {
    background-color: lightgray;
    border-radius: 5px;
    margin-left: 0px;
    margin-right: 0px;
}

.image-preview {
    margin-top: 20px;
}

.image-preview .background {
    position: relative;
    cursor: move;
}

.image-preview .background .remove {
    opacity: 0;
    position: absolute;
    top: 10px;
    right: 10px;
    height: 30px;
    text-align: center;
}

.image-preview .background .edit, .image-preview .background .save {
    opacity: 0;
    position: absolute;
    top: 50px;
    right: 10px;
    height: 30px;
    text-align: center;
}

.image-preview .background .edit button {
    padding: 0.375rem 0.7rem;
}

.image-preview .background:hover .remove, .image-preview .background:hover .edit, .image-preview .background:hover .save {
    opacity: 1;
}

.image-preview .background {
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: contain;
    height: 200px;
    border-radius: 5px;
    background-color: white;
}

.download .image-preview .background .file-type {
    position: absolute;
    top: 10px;
    left: 10px;
    opacity: 1;
}

.image-preview .background a {
    color: gray;
    position: absolute;
    bottom: 10px;
    left: 10px;
    background-color: white;
    padding: 3px;
    border-radius: 3px;
    opacity: 1;
}

.editblock {
    color: gray;
    position: absolute;
    bottom: 10px;
    left: 10px;
    background-color: white;
}

.download .image-preview .background {
    height: 100px;

    background-color: white;
}

#customdropzone {
    background-color: lightcyan;
    border-radius: 5px;
    margin-top: 20px;
    padding: 70px 20px;
}

.download #customdropzone {
    height: 100px;
    padding: 25px;
}

.input_media {
    display: block;
    width: 100%;
}
</style>
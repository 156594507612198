import {commonTableFetch} from "@/store/common-functions";

function getDefaultState() {
    return {
        deposit: {},
        depositsTable: [],
    }
}

const getters = {
    getDeposit: state => state.deposit,
    getDepositsTable: state => state.depositsTable,
}

const actions = {
    fetchDeposits({commit, _}, params) {
        return commonTableFetch(commit, '/deposits', 'setDepositsTable', params)
    },
    fetchDeposit({commit}, {id}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/deposits/' + id, {'skip_loading': true})
                .then(response => {
                    commit('setDeposit', response.data)
                    resolve(response)
                })
        })
    },
    saveRefund({commit}, refund) {
        return new Promise((resolve, reject) => {
            window.axios.post(window.apiUrl + '/deposits/', refund, {'skip_loading': true})
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
}

const mutations = {
    setDepositsTable(state, data) {
        state.depositsTable = data
    },
    setDeposit(state, data) {
        state.deposit = data.refund
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
function getDefaultState() {
    return {
        email_templates: [],
        email_template: {},
        email_blocks: [],
        email_block: {},
        placeholders: [],
        types: [],
    }
}

const getters = {
    getEmailTemplates: state => state.email_templates,
    getEmailTemplate: state => state.email_template,
    getEmailBlocks: state => state.email_blocks,
    getEmailBlock: state => state.email_block,
    getEmailPlaceholders: state => state.placeholders,
    getTypes: state => state.types,
}

const actions = {
    fetchTypes({commit}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/email-template/types', {'skip_loading': true})
                .then(response => {
                    commit('setTypes', response.data)
                    resolve(response)
                })
        })
    },
    fetchTemplates({commit}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/email-template', {'skip_loading': true})
                .then(response => {
                    commit('setTemplates', response.data)
                    resolve(response)
                })
        })
    },
    fetchBlocks({commit}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/email-blocks', {'skip_loading': true})
                .then(response => {
                    commit('setBlocks', response.data)
                    resolve(response)
                })
        })
    },
    fetchTemplate({commit}, id) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/email-template/' + (id ? id : 0), {'skip_loading': true})
                .then(response => {
                    commit('setTemplate', response.data)
                    resolve(response)
                })
        })
    },
    fetchBlock({commit}, id) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/email-blocks/' + (id ? id : 0), {'skip_loading': true})
                .then(response => {
                    commit('setBlock', response.data)
                    resolve(response)
                })
        })
    },
    deleteTemplate({dispatch}, id) {
        return new Promise((resolve) => {
            window.axios.delete(window.apiUrl + '/email-template/' + id).then((response) => {
                resolve(response);
            })
        })
    },
    deleteBlock({dispatch}, id) {
        return new Promise((resolve) => {
            window.axios.delete(window.apiUrl + '/email-blocks/' + id).then((response) => {
                resolve(response);
            })
        })
    },
    saveTemplate({commit}, template) {
        return new Promise((resolve, reject) => {
            window.axios.post(window.apiUrl + '/email-template', template, {'skip_loading': true})
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
    saveBlock({commit}, block) {
        return new Promise((resolve, reject) => {
            window.axios.post(window.apiUrl + '/email-blocks', block, {'skip_loading': true})
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
    sendTestEmail({commit, _}, data) {
        return new Promise((resolve) => {
            window.axios.post(window.apiUrl + '/email-template/test', data, {'skip_loading': true})
                .then(response => {
                    resolve(response)
                })
        })
    },
}

const mutations = {
    setTemplates(state, response) {
        state.email_templates = response.templates
    },
    setTemplate(state, response) {
        state.email_template = response.template
        state.placeholders = response.placeholders
    },
    setBlocks(state, response) {
        state.email_blocks = response.blocks
    },
    setBlock(state, response) {
        state.email_block = response.block
        state.placeholders = response.placeholders
    },
    setTypes(state, response) {
        state.types = response.types
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
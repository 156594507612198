import {commonTableFetch} from "@/store/common-functions";

function getDefaultState() {
    return {
        contract: {
            id: null,
            name: '',
        },
        contractsTable: {},
    }
}

const getters = {
    getContract: state => state.contract,
    getContractsTable: state => state.contractsTable,
}

const actions = {
    fetchContract({commit}, id) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/contract/' + (id ? id : 0), {'skip_loading': true})
                .then(response => {
                    commit('setContract', {...response.data, id})
                    resolve(response)
                })
        })
    },
    saveContract({commit}, contract) {
        return new Promise((resolve, reject) => {
            if (contract.id) {
                window.axios.put(window.apiUrl + '/contract/' + contract.id, contract, {'skip_loading': true})
                    .then(response => {
                        commit('setContract', response.data)
                        resolve(response);
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        reject(error.response.data)
                    })
            } else {
                window.axios.post(window.apiUrl + '/contract', contract)
                    .then(response => {
                        commit('setContract', response.data)
                        resolve(response);
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        reject(error.response.data)
                    })
            }
        })
    },
    deleteContract({dispatch}, {contractId}) {
        return new Promise((resolve) => {
            window.axios.delete(window.apiUrl + '/contract/' + contractId).then((response) => {
                resolve(response);
            })
        })
    },
    deleteContracts({dispatch}, ids) {
        return new Promise((resolve) => {
            let queryString = ids.map((id) => {
                return 'ids[]=' + id
            }).join('&');

            window.axios.delete(window.apiUrl + '/contract?' + queryString).then((response) => {
                resolve(response);
            })
        })
    },
    fetchContracts({commit, _}, params) {
        return commonTableFetch(commit, '/contract', 'setContractsTable', params)
    },
}

const mutations = {
    setContract(state, contract) {
        state.contract = {...getDefaultState().contract, ...contract}
    },
    setContractsTable(state, data) {
        state.contractsTable = data
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
<template>
    <div>
        <b-row class="mb-1"
               v-if="Object.keys(source.filter).length && !(Object.keys(source.filter).length == 1 && typeof source.filter.filterChanged != 'undefined')">
            <b-col>
                <CBadge v-for="key in Object.keys(source.filter)"
                        color="info"
                        :key="key"
                        class="mr-2"
                        v-if="key != 'filterChanged' && key != '_filter_id'"
                        style="line-height:30px"
                >
                    <template v-if="typeof getFilterLabels(key) == 'object'">
                        <span style="color:lightgray">{{ getFilterName(key) }}:</span>
                        <b-button
                            @click="()=>{removeFilter(key)}"
                            class="ml-2" variant="warning" size="sm"
                            v-if="Object.keys(getFilterLabels(key)).length > 1"
                            style="margin-top:-2px;">
                            <font-awesome-icon icon="times"/>
                        </b-button>
                        <span v-for="(filterLabel,filterKey) in getFilterLabels(key)"
                              style="color:white">
                            {{ filterLabel }}
                            <b-button @click="()=>{removeFilterValue(key,filterKey)}" class="ml-2" variant="info"
                                      size="sm" style="margin-top:-2px;">
                            <font-awesome-icon icon="times"/>
                            </b-button>
                        </span>
                    </template>
                    <template v-else>
                        <span style="color:lightgray">{{ getFilterName(key) }}:</span>
                        <span style="color:white">
                            {{ getFilterLabels(key) }}
                            <b-button @click="()=>{removeFilter(key)}" class="ml-2" variant="info" size="sm">
                            <font-awesome-icon icon="times"/>
                        </b-button>
                        </span>
                    </template>


                </CBadge>
                <b-button
                    @click="resetFilters"
                    variant="warning"
                    :title="this.$t('common.button.reset')"
                >
                    <font-awesome-icon icon="ban"/>
                </b-button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    name: 'table-selected-filters',
    props: [
        'value',
        'source',
    ],
    methods: {
        getFilterLabels(key) {
            const field = this.source.fields.find((field) => {
                return field.key == key
            }) || null

            if (field && field.filter && field.filter.type === 'date') {
                let dates = this.source.filter[key].split(";");
                return {
                    'start_date': dates[0],
                    'end_date': dates[1]
                };
            } else if (field && field.filter && field.filter.options) {
                if (field.filter.type == 'select') {
                    let selectedObj = field.filter.options[this.source.filter[key]];
                    let label = key;
                    if (typeof selectedObj['name'] != "undefined") {
                        label = selectedObj['name']
                    }
                    if (typeof selectedObj['label'] != "undefined") {
                        label = selectedObj['label']
                    }
                    return label
                }

                const ids = this.source.filter[key].split(",").map(Number);
                const filteredObject = {};

                ids.forEach(id => {
                    if (field.filter.options.hasOwnProperty(id)) {
                        filteredObject[id] = field.filter.options[id];
                    } else if (id == -1) {
                        filteredObject[id] = 'None';
                    }
                });

                if (Object.keys(filteredObject).length) {
                    return filteredObject;
                }
            }

            return this.source.filter[key]
        },
        getFilterName(key) {
            const field = this.source.fields.find(field => field.key === key) ?? null;

            if (field) {
                return field.title ? field.title : this.$t(field.translation);
            } else {
                return key
            }
        },
        removeFilter(key) {
            if (this.source.monthChanged && key == this.source.dateField) {
                this.source.monthChanged(null)
            }
            this.$delete(this.inputVal, key)
            this.$delete(this.inputVal, '_filter_id')
            this.source.changeBaseFilter(this.inputVal)
        },
        removeFilterValue(key, filterKey) {
            if (this.source.monthChanged && key == this.source.dateField) {
                this.source.monthChanged(null)
            }

            const thisField = this.source.fields.find(field => field.key === key) ?? null;

            if (this.inputVal[key] && thisField.filter.type === 'date') {
                const dates = this.inputVal[key].split(";");
                const newDate = filterKey === 'start_date' ? 'null;' + dates[1] : dates[0] + ';null';

                if (newDate === 'null;null') {
                    this.removeFilter(key);
                } else {
                    this.inputVal[key] = newDate;
                    this.$set(this, 'inputVal', this.inputVal);
                    this.$delete(this.inputVal, '_filter_id')
                    this.source.changeBaseFilter(this.inputVal);
                }
            } else if (this.inputVal[key]) {
                const ids = this.inputVal[key].split(",").map(Number);
                const filteredIds = ids.filter(item => item !== parseInt(filterKey));
                if (filteredIds.length) {
                    this.inputVal[key] = filteredIds.join(",");
                    this.$set(this, 'inputVal', this.inputVal);
                    this.$delete(this.inputVal, '_filter_id')
                    this.source.changeBaseFilter(this.inputVal);
                } else {
                    this.$delete(this.inputVal, '_filter_id')
                    this.removeFilter(key);
                }
            }
        },
        resetFilters() {
            if (this.source.monthChanged) {
                this.source.monthChanged(null)
            }

            this.$set(this, 'inputVal', {})
            this.source.changeBaseFilter({})
        },
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
    },
}
</script>
import {
    commonStoreFetch,
    commonStoreUpdate,
    commonStoreSave,
    commonStoreDelete,
    commonTableFetch
} from "@/store/common-functions";

function getDefaultState() {
    return {
        offerings: [],
        offering: null,
        address: {},
        documents: [],
        events: [],
        events_timing: [],
        attachments: [],
        other_documents: [],
        notices: {},
        vatRequest: {},
        paymentMethods: [],
        returnPosition: [],
        bankTransactionsTable: {},
        historyTable: {},
    }
}

const getters = {
    getOfferings: state => state.offerings,
    getOffering: state => state.offering,
    getNotices: state => state.notices,
    getAddress: state => state.address,
    getDocuments: state => state.documents,
    getAttachments: state => state.attachments,
    getOtherDocuments: state => state.other_documents,
    getVatRequest: state => state.vatRequest,
    getPaymentMethods: state => state.paymentMethods,
    getReturnPosition: state => state.returnPosition,
    getBankTransactionsTable: state => state.bankTransactionsTable,
    getHistoryTable: state => state.historyTable,
    getEvents: state => state.events,
    getEventsTiming: state => state.events_timing,
}

const actions = {
    fetchBankTransactionsTable({commit, _}, params) {
        return commonTableFetch(commit, '/offering/' + params['offering_id'] + '/bank-transactions', 'setBankTransactionTable', params)
    },
    fetchHistoryTable({commit, _}, params) {
        return commonTableFetch(commit, '/offering/' + params['offering_id'] + '/model-history', 'setHistoryTable', params)
    },
    fetchOfferings({commit}) {
        return commonStoreFetch(commit, '/offering', 'setOfferings')
    },
    fetchNotices({commit}, params) {
        return commonTableFetch(commit, '/offering/' + params['offering_id'] + '/notices', 'setNotices', params)
    },
    fetchOffering({commit}, id) {
        return commonStoreFetch(commit, '/offering/' + id, 'setOffering')
    },
    fetchOfferingWithoutLoading({commit}, id) {
        return commonStoreFetch(commit, '/offering/' + id, 'setOffering', true)
    },
    checkOfferingVatId({commit}, id) {
        return commonStoreFetch(commit, '/vat/data/' + id)
    },
    recheckOfferingVatId({commit}, id) {
        return commonStoreFetch(commit, '/vat/check/' + id, 'setVatRequest')
    },
    fetchAddress({commit}, {id, type}) {
        return commonStoreFetch(commit, '/offering/' + id + '/address/' + type, 'setAddress')
    },
    fetchSyncAddress({commit}, {id, type}) {
        return commonStoreFetch(commit, '/offering/' + id + '/address-sync/' + type, 'setAddress')
    },
    fetchSyncProviderAddress({commit}, {id, type}) {
        return commonStoreFetch(commit, '/offering/' + id + '/address-provider-sync/' + type, 'setAddress')
    },
    fetchCopyAddress({commit}, {id, type}) {
        return commonStoreFetch(commit, '/offering/' + id + '/address-copy/' + type, 'setAddress')
    },
    fetchCreateNewCustomer({commit}, {id}) {
        return commonStoreFetch(commit, '/offering/' + id + '/create-new-customer', 'setAddress')
    },
    fetchDocuments({commit}, id) {
        return commonStoreFetch(commit, '/offering/' + id + '/documents', 'setDocuments')
    },
    fetchEvents({commit}, id) {
        return commonStoreFetch(commit, '/offering/' + id + '/events', 'setEvents')
    },
    fetchEventsTiming({commit}, id) {
        return commonStoreFetch(commit, '/offering/' + id + '/events-timing', 'setEventsTiming')
    },
    fetchPaymentMethods({commit}, id) {
        return commonStoreFetch(commit, '/offering/' + id + '/payment-methods', 'setPaymentMethods')
    },
    createOffering(_, offering) {
        return commonStoreSave('/offering', offering)
    },
    changeCustomer(_, offering) {
        return commonStoreSave('/offering/change-customer/' + offering.id, offering)
    },
    addSupplier(_, data) {
        return commonStoreSave('/offering/add-supplier/' + data.id, data)
    },
    removeSupplier(_, data) {
        return commonStoreSave('/offering/remove-supplier/' + data.id, data)
    },
    mainSupplier(_, data) {
        return commonStoreSave('/offering/main-supplier/' + data.id, data)
    },
    updateOffering(_, offering) {
        return commonStoreSave('/offering/update/' + offering.id, offering)
    },
    duplicateOffering(_, data) {
        return commonStoreSave('/offering/duplicate/' + data.id, data)
    },
    offeringReady(_, offering) {
        return commonStoreSave('/offering/ready/' + offering.id, offering)
    },
    saveAddress(_, address) {
        return commonStoreSave('/offering/' + address.project_kvaId + '/address/' + address.id, address)
    },
    assignStocks(_, data) {
        return commonStoreSave('/offering/' + data.id + '/assign-stocks', data)
    },
    generateSupplyRequests(_, data) {
        return commonStoreSave('/offering/' + data.id + '/generate-supply-requests', data)
    },
    appendDiscount(_, data) {
        return commonStoreSave('/offering/' + data.id + '/append-discount', data)
    },
    returnPositionData({commit}, id) {
        return commonStoreFetch(commit, '/offering-positions/return/' + id, 'setReturnPosition')
    },
    returnPosition(_, data) {
        return commonStoreSave('/offering-positions/return/' + data.id, data)
    },
    appendOfferingPosition({commit}, position) {
        return commonStoreSave('/offering/' + position.offeringId + '/position', position, true)
    },
    deletePosition(_, position) {
        return commonStoreDelete('/offering/' + position.offeringId + '/position/' + position.id)
    },
    updateStatus(_, offering) {
        return commonStoreUpdate('/offering/' + offering.id + '/status-update', {status: offering.status})
    },
    updateFulfilmentStatus(_, offering) {
        return commonStoreUpdate('/offering/' + offering.id + '/fulfilment-status-update', {fulfilment_status: offering.fulfilment_status})
    },
    updateSupplyStatus(_, data) {
        return commonStoreUpdate('/offering/' + data.id + '/supply-status-update', {status: data.status_id})
    },
    updatePaymentMethod(_, offering) {
        return commonStoreUpdate('/offering/' + offering.id + '/payment-method-update', {paymentMethod: offering.paymentMethod})
    },
    updatePaymentStatus(_, offering) {
        return commonStoreUpdate('/offering/' + offering.id + '/payment-status-update', {paymentStatus: offering.paymentStatus})
    },
    updatePaymentDate(_, offering) {
        return commonStoreUpdate('/offering/' + offering.id + '/payment-date-update', {date: offering.date})
    },
    updateShippingMethod(_, offering) {
        return commonStoreUpdate('/offering/' + offering.id + '/shipping-method-update', {shippingMethod: offering.shippingMethod})
    },
    updateShippingStatus(_, offering) {
        return commonStoreUpdate('/offering/' + offering.id + '/shipping-status-update', {shipping_status: offering.shipping_status})
    },
    updateNote(_, notice) {
        return commonStoreUpdate('/offering/' + notice.offeringId + '/notice/' + notice.id, notice)
    },
    createNotice(_, notice) {
        return commonStoreSave('/offering/' + notice.offeringId + '/notice/', notice)
    },
    deleteNotice(_, id) {
        return commonStoreDelete('/offering/notice/' + id)
    },
    deleteOffering(_, id) {
        return commonStoreDelete('/offering/' + id)
    },
    deleteDocument(_, name) {
        return commonStoreDelete('/offering/document/' + name)
    },
}

const mutations = {
    setOfferings(state, offerings) {
        state.offerings = offerings
    },
    setOffering(state, offering) {
        state.offering = offering
    },
    setNotices(state, data) {
        state.notices = data
    },
    setAddress(state, data) {
        state.address = data.address
    },
    setVatRequest(state, data) {
        state.vatRequest = data
    },
    setDocuments(state, data) {
        state.documents = data.documents
        state.attachments = data.attachments
        state.other_documents = data.other_documents
    },
    setPaymentMethods(state, paymentMethods) {
        state.paymentMethods = paymentMethods
    },
    setEvents(state, data) {
        state.events = data.events
    },
    setEventsTiming(state, data) {
        state.events_timing = data.events
    },
    setReturnPosition(state, result) {
        state.returnPosition = result
    },
    setBankTransactionTable(state, data) {
        state.bankTransactionsTable = data
    },
    setHistoryTable(state, data) {
        state.historyTable = data
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
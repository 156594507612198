<template>
    <ValidationProvider :name="name" :rules="validate" v-slot="validationContext">
        <b-form-group>
            <template v-if="!disableLabel" v-slot:label>
                <template v-if="label">{{ label }}</template>
                <template v-else>
                    {{ $t(labelPrefix + name) }}
                </template>
                <span v-if="validationContext.required" class="text-danger">*</span>
            </template>
            <b-input-group>
                <b-input-group-prepend v-if="icon" is-text>
                    <font-awesome-icon :icon="icon"/>
                </b-input-group-prepend>
                <input type="hidden" v-model="inputVal">
                <date-picker :value="getDateValue()"
                             :readonly="readonly"
                             :config="config"
                             @input="(val)=>{dateChanged(val)}"
                             ref="datePicker"
                             :class="inputClasses(validationContext)"
                             autocomplete="off"
                ></date-picker>
                <b-input-group-append>
                    <b-button @click="openDatePicker">
                        <font-awesome-icon icon="calendar"/>
                    </b-button>
                </b-input-group-append>
            </b-input-group>

            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
    </ValidationProvider>
</template>

<script>
import validateMixin from '../mixins/validate'
import moment from 'moment'

export default {
    props: {
        'value': [String, Number],
        'name': {
            type: String,
            required: true
        },
        'validate': [String, Object],
        'icon': [String, Array],
        'label': String,
        'labelPrefix': {
            type: String,
            default: 'common.label.'
        },
        'disableLabel': {
            type: Boolean,
            default: false
        },
        'minDate': {
            type: [String, Date, Boolean],
            default: () => {
                return false
            }
        },
        'maxDate': {
            type: [String, Date, Boolean],
            default: () => {
                return false
            }
        },
        // 'min': [String, Date],
        // 'max': [String, Date],
        'readonly': {
            type: Boolean,
            default: false
        },
        'size': {
            type: String,
            default: 'lg'
        },
        'format': {
            type: String,
            default: 'DD.MM.YYYY'
        }
    },
    data() {
        return {
            config: {
                format: this.format,
                useCurrent: false,
                showClear: true,
                locale: 'de',
                minDate: this.minDate == '' ? false : this.minDate,
                maxDate: this.maxDate == '' ? false : this.maxDate,
                keyBinds: {
                    right: null,
                    left: null,
                    delete: null,
                }
            }
        }
    },
    mixins: [validateMixin],
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
    },
    methods: {
        getDateValue() {
            return moment(this.inputVal).format(this.format)
        },
        openDatePicker: function () {
            this.$refs.datePicker.$el.focus()
        },
        dateChanged(date) {
            const momentDate = moment(date, this.format, null, true)
            if (momentDate.isValid()) {
                this.$emit('input', momentDate.format('YYYY-MM-DD'));
            } else {
                this.$emit('input', null);
            }

        },
        inputClasses(validationContext) {
            let classes = []
            if (validationContext.valid == false) {
                classes.push('is-invalid')
            }
            classes.push('form-control-' + this.size)

            return classes.join(' ')
        }
    }
}
</script>
import {commonStoreSave, commonTableFetch} from "@/store/common-functions";

function getDefaultState() {
    return {
        supplyStatuses: [],
        suppliesTable: {},
        supply: {},
        supplyReceived: {},
    }
}

const getters = {
    getSupplyStatuses: state => state.supplyStatuses,
    getSuppliesTable: state => state.suppliesTable,
    getSupply: state => state.supply,
    getSupplyReceived: state => state.supplyReceived,
}

const actions = {
    fetchSupplyStatuses({commit}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/supply/statuses', {'skip_loading': true})
                .then(response => {
                    commit('setSupplyStatuses', response.data)
                    resolve(response)
                })
        })
    },
    fetchSupply({commit}, id) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/supply/' + (id || 0), {'skip_loading': true})
                .then(response => {
                    commit('setSupply', response.data)
                    resolve(response)
                })
        })
    },
    fetchSupplyReceived({commit}, id) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/supply/received/' + id, {'skip_loading': true})
                .then(response => {
                    commit('setSupplyReceived', response.data)
                    resolve(response)
                })
        })
    },
    saveSupply({commit}, supply) {
        return new Promise((resolve, reject) => {
            window.axios.post(window.apiUrl + '/supply', supply, {'skip_loading': true})
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
    saveReceived({commit}, data) {
        return new Promise((resolve, reject) => {
            window.axios.post(window.apiUrl + '/supply/received/' + data.id, data, {'skip_loading': true})
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
    stockPush({commit}, data) {
        return commonStoreSave('/supply/push', data, true)
    },
    supplyStockPush({commit}, data) {
        return commonStoreSave('/supply/stock-push', data, true)
    },
    saveCancel({commit}, data) {
        return new Promise((resolve, reject) => {
            window.axios.post(window.apiUrl + '/supply/cancel/' + data.id, data, {'skip_loading': true})
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
    fetchSupplies({commit, _}, params) {
        return commonTableFetch(commit, '/supplies/' + params.articleId, 'setSuppliesTable', params)
    },
}

const mutations = {
    setSupplyStatuses(state, response) {
        state.supplyStatuses = response
    },
    setSuppliesTable(state, data) {
        state.suppliesTable = data
    },
    setSupply(state, data) {
        state.supply = data.supply
    },
    setSupplyReceived(state, data) {
        state.supplyReceived = data.supply
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
function getDefaultState() {
    return {
        modules: [],
        permissions: {},
        module: {},
    }
}

const getters = {
    getModules: state => state.modules,
    getPermissions: state => state.permissions,
    getModule: state => state.module,
}

const actions = {
    fetchModules({commit}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/settings/module', {'skip_loading': true})
                .then(response => {
                    commit('setModules', response.data)
                    resolve(response)
                })
        })
    },
    fetchModule({commit}, {id}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/settings/module/' + id, {'skip_loading': true})
                .then(response => {
                    commit('setModule', response.data)
                    resolve(response)
                })
        })
    },
    saveModule({commit}, module) {
        return new Promise((resolve, reject) => {
            window.axios.post(window.apiUrl + '/settings/module/' + module.id, module, {'skip_loading': true})
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
}

const mutations = {
    setModules(state, response) {
        state.modules = response.modules
        state.permissions = response.permissions
    },
    setModule(state, response) {
        state.module = response.module
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
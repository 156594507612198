<template>
    <ValidationProvider :name="name" :rules="validate" v-slot="validationContext">
        <b-form-group>
            <template v-slot:label>
                <template v-if="label">{{ label }}</template>
                <template v-else>
                    {{ $t(labelPrefix + name) }}
                </template>
                <span v-if="validationContext.required" class="text-danger">*</span>
            </template>
            <b-input-group>
                <b-input-group-prepend v-if="icon" is-text>
                    <font-awesome-icon :icon="icon"/>
                </b-input-group-prepend>
                <b-form-input
                    v-model="date"
                    type="text"
                    placeholder="HH:mm"
                ></b-form-input>
                <b-input-group-append>
                    <b-form-timepicker
                        v-model="inputVal"
                        button-only
                        right
                        :hour12="false"
                        :state="getValidationState(validationContext)"
                    ></b-form-timepicker>
                </b-input-group-append>
            </b-input-group>


            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
    </ValidationProvider>
</template>

<script>
import validateMixin from '../mixins/validate'

export default {
    props: {
        'value': [String, Number],
        'name': {
            type: String,
            required: true
        },
        'validate': [String, Object],
        'icon': [String, Array],
        'label': String,
        'labelPrefix': {
            type: String,
            default: 'common.label.'
        },
    },
    data() {
        return {
            date: this.value,
        }
    },
    mixins: [validateMixin],
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.date = val ? val.substring(0, 5) : null
                this.$emit('input', val);
            }
        }
    },
    watch: {
        date: function (val) {
            this.$emit('input', val);
        },
    }
}
</script>
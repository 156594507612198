import {commonTableFetch} from "@/store/common-functions";

function getDefaultState() {
    return {
        commonTable: {},
    }
}

const getters = {
    getCommonTable: state => state.commonTable,
}

const actions = {
    fetchTableData({commit, _}, params) {
        return commonTableFetch(commit, params.url, 'setCommonTable', params)
    },
}

const mutations = {
    setCommonTable(state, data) {
        state.commonTable = data
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
import {commonStoreFetch, commonStoreSave, commonTableFetch} from "@/store/common-functions";

function getDefaultState() {
    return {
        article: null,
        custom_prices: [],
        article_shelves: [],
        price_lists: [],
        simples: [],
        types: [],
        articleNetworks: [],
        articlesTable: {},
        articlesInventoryTable: {},
        articlesInventoryListTable: {},
        articlesTree: {},
    }
}

const getters = {
    getArticle: state => state.article,
    getCustomPrices: state => state.custom_prices,
    getPriceLists: state => state.price_lists,
    getSimples: state => state.simples,
    getArticleShelves: state => state.article_shelves,
    getArticlesTable: state => state.articlesTable,
    getArticlesInventoryTable: state => state.articlesInventoryTable,
    getArticlesInventoryListTable: state => state.articlesInventoryListTable,
    getTypes: state => state.types,
    getArticleNetworks: state => state.articleNetworks,
}

const actions = {
    fetchArticlesTree({commit}, id) {
        return commonStoreFetch(commit, '/articles/tree/' + (id ? id : 0))
    },
    searchArticlesTree({commit}, search) {
        return commonStoreFetch(commit, '/articles/tree/search/' + search)
    },
    fetchArticle({commit}, id) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/articles/' + (id ? id : 0), {'skip_loading': true})
                .then(response => {
                    commit('setArticle', {...response.data, id})
                    resolve(response)
                })
        })
    },
    fetchArticleNetworks({commit}, id) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/articles/networks/' + (id ? id : 0), {'skip_loading': true})
                .then(response => {
                    commit('setArticleNetworks', {...response.data})
                    resolve(response)
                })
        })
    },
    fetchTypes({commit}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/articles/types', {'skip_loading': true})
                .then(response => {
                    commit('setTypes', response.data)
                    resolve(response)
                })
        })
    },
    fetchCategoryArticles(_, id) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/articles/by-category/' + id, {'skip_loading': true})
                .then(response => {
                    resolve(response)
                })
        })
    },
    fetchArticles({commit, _}, params) {
        return commonTableFetch(commit, '/articles', 'setArticlesTable', params)
    },
    fetchArticlesInventory({commit, _}, params) {
        return commonTableFetch(commit, '/articles/inventory', 'setArticlesInventoryTable', params, {date: params.date})
    },
    fetchArticlesInventoryList({commit, _}, params) {
        return commonTableFetch(commit, '/articles/inventory-list', 'setArticlesInventoryListTable', params, {date: params.date})
    },
    savePriceLists({commit}, data) {
        return commonStoreSave('/articles/prices/' + data.article_id, data)
    },
    saveArticle({commit}, article) {
        return new Promise((resolve, reject) => {
            window.axios.post(window.apiUrl + '/articles/' + (article.id ? article.id : 0), article, {'skip_loading': true})
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
    regenerateJsonConfig({commit}, article) {
        return new Promise((resolve, reject) => {
            window.axios.post(window.apiUrl + '/articles/regenerate-json-config', article, {'skip_loading': true})
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },

    deleteArticle({dispatch}, id) {
        return new Promise((resolve) => {
            window.axios.delete(window.apiUrl + '/articles/' + id).then((response) => {
                resolve(response);
            })
        })
    },
    deleteArticles({dispatch}, ids) {
        return new Promise((resolve) => {
            let queryString = ids.map((id) => {
                return 'ids[]=' + id
            }).join('&');

            window.axios.delete(window.apiUrl + '/articles?' + queryString).then((response) => {
                resolve(response);
            })
        })
    },
}

const mutations = {
    setArticle(state, response) {
        state.article = response.article
        state.custom_prices = response.custom_prices
        state.article_shelves = response.article_shelves
        state.price_lists = response.price_lists
        state.simples = response.parents
    },
    setTypes(state, response) {
        state.types = response.types
    },
    setArticlesTable(state, data) {
        state.articlesTable = data
    },
    setArticlesInventoryTable(state, data) {
        state.articlesInventoryTable = data
    },
    setArticlesInventoryListTable(state, data) {
        state.articlesInventoryListTable = data
    },
    setArticleNetworks(state, articleNetworks) {
        state.articleNetworks = articleNetworks.networks
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
<template>
    <ValidationProvider :name="name" :rules="validate" v-slot="validationContext">
        <b-form-group>
            <template v-if="!disableLabel" v-slot:label>
                <template v-if="label">{{ label }}</template>
                <template v-else>
                    {{ $t(labelPrefix + name) }}
                </template>
                <span v-if="validationContext.required" class="text-danger">*</span>
            </template>
            <b-input-group>
                <b-input-group-prepend v-if="icon" is-text>
                    <font-awesome-icon :icon="icon"/>
                </b-input-group-prepend>
                <b-form-textarea
                    v-model="inputVal"
                    :placeholder="placeholder"
                    :state="getValidationState(validationContext)"
                    :readonly="readonly"
                    rows="3"
                    :max-rows="maxRows"
                    v-on="$listeners"
                    :size="size"
                    ref="textarea"
                ></b-form-textarea>
            </b-input-group>
            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
    </ValidationProvider>
</template>

<script>
import validateMixin from '../mixins/validate'

export default {
    props: {
        'name': {
            type: String,
            required: true
        },
        'value': String,
        'validate': [String, Object],
        'icon': [String, Array],
        'placeholder': String,
        'label': String,
        'labelPrefix': {
            type: String,
            default: 'common.label.'
        },
        'size': {
            type: String,
            default: 'lg'
        },
        'maxRows': {
            type: Number,
            default: 6
        },
        'readonly': {
            type: Boolean,
            default: false
        },
        'disableLabel': {
            type: Boolean,
            default: false
        },
    },
    mixins: [validateMixin],
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        }
    },
}
</script>
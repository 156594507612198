<template>
    <validation-provider :name="name" :rules="validate" v-slot="validationContext">
        <b-form-group>
            <template v-if="!disableLabel" v-slot:label>
                <template v-if="label">{{ label }}</template>
                <template v-else>
                    {{ $t(labelPrefix + name) }}
                </template>
                <span v-if="validationContext.required" class="text-danger">*</span>
            </template>
            <input type="hidden" v-model="inputVal">
            <vue-typeahead-bootstrap
                ref="typeahead"
                v-model="search"
                :data="data"
                :class="!getValidationState(validationContext) ? 'is-invalid' : 'is-valid'"
                :inputClass="(getValidationState(validationContext) == null ? '' :(getValidationState(validationContext) === true ? 'is-valid' : 'is-invalid')) + inputClass"
                :serializer="serializer"
                @hit="optionSelected($event)"
                @input="input"
                :disabled="readonly"
                :placeholder="placeholder"
                :minMatchingChars="minMatchingChars"
            >
            </vue-typeahead-bootstrap>
            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
    </validation-provider>
</template>

<script>
import _ from 'underscore'

export default {
    props: {
        'name': {
            type: String,
            required: true
        },
        'inputClass': {
            type: String,
            default: '',
        },
        'value': [String, Number, Object],
        'url': {
            type: String,
        },
        'selectRequired': {
            type: Boolean,
            default: true
        },
        'validate': [String, Object],
        'label': String,
        'placeholder': String,
        'labelPrefix': {
            type: String,
            default: 'common.label.'
        },
        'readonly': {
            type: Boolean,
            default: false
        },
        'clean': {
            type: Boolean,
            default: false
        },
        'disableLabel': {
            type: Boolean,
            default: false
        },
        'callback': [Function],
        'input': [Function],
        'serializer': {
            type: Function,
            default: s => s.label
        },
        additionalParams: {
            type: [Object],
            default: () => {
                return {}
            }
        },
        minMatchingChars: {
            type: [Number],
            default: () => {
                return 1
            }
        }
    },
    data() {
        return {
            search: '',
            data: [],
        }
    },
    mounted() {
        if (this.value) {
            this.start()
        }
    },
    computed: {
        inputVal: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            }
        }
    },
    methods: {
        getValidationState({dirty, validated, valid = null}) {
            return dirty || validated ? valid : null;
        },
        getData(query) {
            if (!query || !query.length) {
                return
            }
            let params = JSON.parse(JSON.stringify(this.additionalParams))
            params.search = query
            this.axios.post(this.url, params, {'skip_loading': true})
                .then(response => {
                    this.data = response.data
                    if (!this.selectRequired) {
                        this.data.unshift({'id': this.search, 'label': this.search});
                    }
                })
        },
        optionSelected(option) {
            this.$emit('input', option.id)
            this.$emit('input-object', option)

            if (this.callback) {
                this.callback(option)
            }

            if (this.clean) {
                this.search = null;
            }
        },
        start() {
            if (this.value) {
                let params = JSON.parse(JSON.stringify(this.additionalParams))
                params.id = this.value

                this.axios.post(this.url, params).then(response => {
                    this.data = response.data

                    this.search = this.data[0] ? this.data[0].label : ''
                    this.$refs.typeahead.inputValue = this.search

                    this.$emit('input-object', this.data[0])
                })
            }
        }
    },
    watch: {
        search: _.debounce(function (query) {
            this.getData(query)
        }, 250),
        value: function (val) {
            this.start()
        }
    }
}
</script>

<style>
.vbt-autcomplete-list {
    z-index: 3000 !important;
    background-color: #ebedef;
}
</style>
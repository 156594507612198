import {commonTableFetch} from "@/store/common-functions";

function getDefaultState() {
    return {
        transfer: {},
        transfersTable: []
    }
}

const getters = {
    getTransfer: state => state.transfer,
    getTransfersTable: state => state.transfersTable,
}

const actions = {
    fetchTransfers({commit, _}, params) {
        return commonTableFetch(commit, '/transfers', 'setTransfersTable', params)
    },
    fetchTransfer({commit}, {id}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/transfers/' + id, {'skip_loading': true})
                .then(response => {
                    commit('setTransfer', response.data)
                    resolve(response)
                })
        })
    },
    saveTransfer({commit}, transfer) {
        return new Promise((resolve, reject) => {

            window.axios.post(window.apiUrl + '/transfers/' + transfer.id, transfer, {
                'skip_loading': true
            })
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
}

const mutations = {
    setTransfersTable(state, data) {
        state.transfersTable = data
    },
    setTransfer(state, data) {
        state.transfer = data.transfer
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
import i18n from '../../i18n'
import {commonTableFetch} from "@/store/common-functions";

function getDefaultState() {
    return {
        groups: [],
        messages: {},
        language: {},
        languages: [],
        availableLocales: [],
        messagesTable: {},
    }
}

const getters = {
    locale: () => i18n.locale,
    groups: state => state.groups,
    messages: state => state.messages,
    getLanguage: state => state.language,
    languages: state => state.languages,
    getMessagesTable: state => state.messagesTable,
    availableLocales: state => state.availableLocales
}

const actions = {
    fetchMessagesTable({commit, _}, params) {
        return commonTableFetch(commit, '/locales/all-messages', 'setMessagesTable', params)
    },
    fetchLanguages({commit, dispatch}) {
        window.axios.get(window.apiUrl + '/locales/languages').then(response => {
            commit('setLanguages', response.data.languages)
            // dispatch('setLocale', localStorage.locale ?? response.data.locale)
        })
    },
    fetchLanguage({commit, dispatch}, id) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/locales/languages/' + (id ? id : 0), {'skip_loading': true})
                .then(response => {
                    commit('setLanguage', response.data)
                    resolve(response)
                })
        })
    },
    fetchGroups({commit, dispatch}) {
        window.axios.get(window.apiUrl + '/locales/groups').then(response => {
            commit('setGroups', response.data)
        })
    },
    fetchGroupMessages({commit, getters}, group) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/locales/messages/' + group, {'skip_loading': true})
                .then(response => {
                    resolve(response.data)
                })
        })
    },
    fetchMessages({commit, getters}) {
        window.axios.defaults.headers['Accept-Language'] = localStorage.locale
        return window.axios.get(window.apiUrl + '/locales/messages').then(response => {
            i18n.mergeLocaleMessage('en', response.data.en)
            if (localStorage.locale != 'en') {
                i18n.mergeLocaleMessage(localStorage.locale, response.data[localStorage.locale])
            }

            commit('setMessages', response.data)
        })
    },
    saveTranslation({commit}, translation) {
        return new Promise((resolve, reject) => {
            window.axios.post(window.apiUrl + '/locales/messages', translation, {'skip_loading': true})
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
    saveLanguage({commit}, language) {
        return new Promise((resolve, reject) => {
            window.axios.post(window.apiUrl + '/locales/languages/' + (language.id ? language.id : 0), language, {'skip_loading': true})
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
    importTranslations({commit}, insurance) {
        return new Promise((resolve, reject) => {
            const formData = new FormData();

            Object.entries(insurance).forEach(([key, value]) => {
                formData.append(key, value);
            });

            window.axios.post(window.apiUrl + '/locales/import-translations', formData, {
                'skip_loading': true,
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            })
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
    deleteLanguage({dispatch}, id) {
        return new Promise((resolve) => {
            window.axios.delete(window.apiUrl + '/locales/languages/' + id).then((response) => {
                resolve(response);
            })
        })
    },
}

const mutations = {
    setMessagesTable(state, data) {
        state.messagesTable = data
    },
    setLanguages(state, languages) {
        state.languages = languages
    },
    setLanguage(state, data) {
        state.language = data.language
    },
    setLocale(state, locale) {
        i18n.locale = locale
        localStorage.locale = locale
    },
    setMessages(state, messages) {
        state.messages = messages
    },
    setGroups(state, groups) {
        state.groups = groups
    },
    addAvailableLocale(state, locale) {
        state.availableLocales.push(locale)
    },
    setAcceptLanguage(state, locale) {
        window.axios.defaults.headers['Accept-Language'] = locale
    }
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
import {
    commonStoreFetch,
    commonStoreUpdate,
    commonStoreSave,
    commonStoreDelete,
    buildFormData, commonTableFetch
} from "@/store/common-functions";

function getDefaultState() {
    return {
        articlesTable: {},
        store: {},
        stores: [],
        attributes: [],
        projectAttributes: [],
        amazonAttributes: [],
        ottoCategories: [],
        woocommerceCategories: [],
        magentoSets: [],
        magentoAttributes: [],
        magentoAttributesMap: [],
    }
}

const getters = {
    getArticlesTable: state => state.articlesTable,
    getStore: state => state.store,
    getStores: state => state.stores,
    getAttributes: state => state.attributes,
    getAmazonAttributes: state => state.amazonAttributes,
    getProjectAttributes: state => state.projectAttributes,
    getOttoCategories: state => state.ottoCategories,
    getWoocommerceCategories: state => state.woocommerceCategories,
    getMagentoSets: state => state.magentoSets,
    getMagentoAttributes: state => state.magentoAttributes,
    getMagentoAttributesMap: state => state.magentoAttributesMap,
}

const actions = {
    fetchArticles({commit, _}, params) {
        return commonTableFetch(commit, '/multi-channel', 'setArticlesTable', params)
    },
    fetchStores({commit, _}, sourceId) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/multi-channel/stores/' + sourceId)
                .then(response => {
                    commit('setStores', response.data)
                    resolve(response.data)
                })
        })
    },
    fetchMagentoSets({commit, _}, sourceId) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/multi-channel/' + sourceId + '/magento-sets')
                .then(response => {
                    commit('setMagentoSets', response.data)
                    resolve()
                })
        })
    },
    fetchMagentoAttributes({commit, _}, {sourceId, setId}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/multi-channel/' + sourceId + '/magento-attributes/' + setId)
                .then(response => {
                    commit('setMagentoAttributes', response.data)
                    resolve()
                })
        })
    },
    unassignMagentoAttributes({commit, _}, {attributeSetId, erikaSetId}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/multi-channel/' + attributeSetId + '/unassign-magento-attributes/' + erikaSetId)
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
    fetchStore({commit, _}, storeId) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/multi-channel/store/' + storeId, {'skip_loading': true})
                .then(response => {
                    commit('setStore', response.data)
                    resolve()
                })
        })
    },
    syncStores({commit, _}, sourceId) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/multi-channel/' + sourceId + '/right-sync-stores')
                .then(response => {
                    resolve()
                })
        })
    },
    getProducts({commit, _}, sourceId) {
        return commonStoreFetch(commit, '/multi-channel/get-products/' + sourceId)
    },
    syncCategories({commit, _}, sourceId) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/multi-channel/' + sourceId + '/right-sync-categories')
                .then(response => {
                    resolve()
                })
        })
    },
    syncWoocommerceCategories({commit, _}, sourceId) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/multi-channel/' + sourceId + '/woocommerce-sync-categories')
                .then(response => {
                    resolve()
                })
        })
    },
    fetchAttributes({commit}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/multi-channel/attributes', {'skip_loading': true})
                .then(response => {
                    commit('setAttributes', response.data)
                    resolve(response)
                })
        })
    },
    fetchAmazonAttributes({commit}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/multi-channel/amazon-attributes', {'skip_loading': true})
                .then(response => {
                    commit('setAmazonAttributes', response.data)
                    resolve(response)
                })
        })
    },
    fetchOttoCategories({commit}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/multi-channel/otto-categories', {'skip_loading': true})
                .then(response => {
                    commit('setOttoCategories', response.data)
                    resolve(response)
                })
        })
    },
    fetchWoocommerceCategories({commit}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/multi-channel/woocommerce-categories', {'skip_loading': true})
                .then(response => {
                    commit('setWoocommerceCategories', response.data)
                    resolve(response)
                })
        })
    },
    fetchMagentoAttributesMap({commit}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/multi-channel/magento-attributes', {'skip_loading': true})
                .then(response => {
                    commit('setMagentoAttributesMap', response.data)
                    resolve(response)
                })
        })
    },
    updateAttributes({commit}, form) {
        return new Promise((resolve, reject) => {
            window.axios.post(window.apiUrl + '/channel-article/attributes', form, {'skip_loading': true})
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
    updatePrice({commit}, data) {
        return new Promise((resolve, reject) => {
            window.axios.post(window.apiUrl + '/channel-article/change-price', data, {'skip_loading': true})
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
    sync({commit}, data) {
        return new Promise((resolve, reject) => {
            window.axios.post(window.apiUrl + '/multi-channel/sync', data, {'skip_loading': true})
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
    append({commit}, data) {
        return new Promise((resolve, reject) => {
            window.axios.post(window.apiUrl + '/multi-channel/append', data, {'skip_loading': true})
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
    saveChannel({commit}, data) {
        return new Promise((resolve, reject) => {
            const formData = new FormData();

            buildFormData(formData, data)

            Object.entries(data).forEach(([key, value]) => {
                if (['combine_list_1', 'combine_list_2'].includes(key)) {
                    formData.append(key, value)
                }
            });

            window.axios.post(window.apiUrl + '/multi-channel/' + (data.id ? data.id : 0), formData, {
                'skip_loading': true,
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            })
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
    saveAmazonAttributes({commit}, data) {
        return new Promise((resolve, reject) => {

            window.axios.post(window.apiUrl + '/multi-channel/amazon-attributes', data, {
                'skip_loading': true,
            })
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
    saveMagentoAttributes({commit}, data) {
        return new Promise((resolve, reject) => {

            window.axios.post(window.apiUrl + '/multi-channel/magento-attributes', data, {
                'skip_loading': true,
            })
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
    saveOttoCategories({commit}, data) {
        return new Promise((resolve, reject) => {

            window.axios.post(window.apiUrl + '/multi-channel/otto-categories', data, {
                'skip_loading': true,
            })
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
    saveWoocommerceCategories({commit}, data) {
        return new Promise((resolve, reject) => {

            window.axios.post(window.apiUrl + '/multi-channel/woocommerce-categories', data, {
                'skip_loading': true,
            })
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
    checkWeight(_, data) {
        return commonStoreSave('/multi-channel/check-weight', data)
    },
    checkSetId(_, data) {
        return commonStoreSave('/multi-channel/check-set-id', data)
    },
    assign(_, data) {
        return commonStoreSave('/multi-channel/unassigned/assign', data)
    },
    unassign(_, data) {
        return commonStoreSave('/multi-channel/unassigned/unassign', data)
    },
}

const mutations = {
    setArticlesTable(state, data) {
        state.articlesTable = data
    },
    setStore(state, data) {
        state.store = data
    },
    setStores(state, data) {
        state.stores = data
    },
    setAttributes(state, response) {
        state.attributes = response.attributes
    },
    setAmazonAttributes(state, response) {
        state.amazonAttributes = response.amazon_attributes
        state.projectAttributes = response.attributes
    },
    setOttoCategories(state, response) {
        state.ottoCategories = response
    },
    setWoocommerceCategories(state, response) {
        state.woocommerceCategories = response
    },
    setMagentoSets(state, response) {
        state.magentoSets = response
    },
    setMagentoAttributes(state, response) {
        state.magentoAttributes = response
    },
    setMagentoAttributesMap(state, response) {
        state.magentoAttributesMap = response
    }
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
<template>
    <div class="row todos">
        <div class="col-sm-3 " v-for="status in getTodoStatues()">
            <div class="column">
                <b>{{ $t('common.todo.status.' + status) }}</b>
                <div class="todo"
                     v-for="todo in todos"
                     v-if="status == todo.status"
                     @click="$root.$children[0].openModal('todo-modal',{id:todo.id}, callback)"
                >
                    <div class="todo-title">{{ todo.title }}</div>
                    <div>{{ $t('common.todo.label.user') }}:
                        {{ todo.user ? todo.user.vorname + ' ' + todo.user.name : '-' }}
                    </div>
                    <div>{{ $t('common.todo.label.action') }}:
                        {{ formatTodo(todo.action) }}
                    </div>
                    <div>{{ $t('common.todo.label.deadline') }}: {{
                            todo.deadline ? todo.deadline :
                                '-'
                        }}
                    </div>
                    <div>{{ $t('common.todo.label.notification') }}: {{
                            todo.notification_enabled ? todo.notification_date :
                                '-'
                        }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    name: 'Todos',
    props: ['todos', 'callback'],
    data() {
        return {}
    },
    methods: {
        ...mapGetters('CommonData', ['getTodoStatues', 'getTodoActions']),
        formatTodo(action) {
            let actions = this.getTodoActions()
            if (typeof actions[action] != "undefined") {
                return actions[action];
            }

            return action;
        }
    }
}
</script>

<style>
.todos .column {
    background-color: lightgray;
    padding: 5px;
    border-radius: 5px;
}

.todos .col-sm-3 {
    padding-right: 5px;
    padding-left: 5px;
}

.todos .todo {
    background-color: white;
    padding: 5px;
    border-radius: 5px;
    margin-bottom: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
}

.todo-title {
    font-size: 15px;
    color: #4682b4;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
</style>
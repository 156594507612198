import {commonTableFetch} from "@/store/common-functions";

function getDefaultState() {
    return {
        statuses: [],
        status: {},
        statusesTable: {},
        statusesList: [],
    }
}

const getters = {
    getStatuses: state => state.statuses,
    getStatus: state => state.status,
    getStatusesTable: state => state.statusesTable,
    getEntityStatusesList: state => state.statusesList,
}

const actions = {
    fetchStatuses({commit, _}, params) {
        return commonTableFetch(commit, '/entity-status', 'setStatusesTable', params)
    },
    fetchUsersList({commit, _}, params) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/entity-status/list', {'skip_loading': true})
                .then(response => {
                    commit('setEntityStatusesList', response.data)
                    resolve()
                })
        })
    },
    fetchStatus({commit}, id) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/entity-status/' + (id ? id : 0), {'skip_loading': true})
                .then(response => {
                    commit('setStatus', response.data)
                    resolve(response)
                })
        })
    },
    deleteStatus({dispatch}, id) {
        return new Promise((resolve) => {
            window.axios.delete(window.apiUrl + '/entity-status/' + id).then((response) => {
                resolve(response);
            })
        })
    },
    saveStatus({commit}, status) {
        return new Promise((resolve, reject) => {
            window.axios.post(window.apiUrl + '/entity-status', status, {'skip_loading': true})
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
}

const mutations = {
    setStatuses(state, response) {
        state.statuses = response.statuses
    },
    setStatus(state, response) {
        state.status = response.status
    },
    setStatusesTable(state, data) {
        state.statusesTable = data
    },
    setEntityStatusesList(state, statusesList) {
        state.statusesList = statusesList
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
import {
    commonStoreFetch,
} from "@/store/common-functions";

function getDefaultState() {
    return {
        files: [],
    }
}

const getters = {
    getFiles: state => state.files,
}

const actions = {
    fetchFiles({commit}) {
        return commonStoreFetch(commit, '/ckeditor/browse', 'setFiles')
    },
}

const mutations = {
    setFiles(state, response) {
        state.files = response.files
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
import {
    commonStoreFetch,
    commonStoreUpdate,
    commonStoreSave,
    commonStoreDelete,
    commonTableFetch
} from "@/store/common-functions";

function getDefaultState() {
    return {
        historyTable: {},
    }
}

const getters = {
    getHistoryTable: state => state.historyTable,
}

const actions = {
    fetchHistoryTable({commit, _}, params) {
        return commonTableFetch(commit, '/import/history', 'setHistoryTable', params)
    },
}

const mutations = {
    setHistoryTable(state, data) {
        state.historyTable = data
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
<template>
    <draggable :class="{[`cursor-grabbing`]: dragging === true} && {[`download`]: eventType == 'download'}"
               class="row files-wrapper pb-3 mb-3"
               v-model="files"
               group="files"
               @start="dragging = true"
               @end="dragging = false"
               tag="div"
               handle=".image-preview"
    >
        <b-col v-for="(file,index) in files"
               :key="index"
               cols="2"
               class="image-preview"
        >
            <div class="background" :style="'background-image: url('+imagesUrl(file)+')'">

                <font-awesome-icon class="file-type"
                                   v-if="file.split('.').pop() == 'pdf'"
                                   size="4x"
                                   icon="file-pdf"
                ></font-awesome-icon>

                <a target="_blank" :href="eventType == 'download' ? fileUrl(file) : imagesUrl(file)">{{ file }}</a>

                <div class="remove">
                    <b-button variant="danger" @click="removeFile(file)">
                        <font-awesome-icon icon="trash"></font-awesome-icon>
                    </b-button>
                </div>
            </div>
        </b-col>
        <b-col cols="2">
            <vue-dropzone ref="dz"
                          id="customdropzone"
                          :options="dropzoneOptions"
                          :include-styling="false"
                          @vdropzone-success="uploaded"
                          @vdropzone-error="errorUpload"
                          @vdropzone-error-multiple="errorUpload"
                          use-custom-slot
            >
                <div>
                    <font-awesome-icon icon="upload" size="2x"></font-awesome-icon>
                </div>
                <div>{{ $t('common.button.media_upload') }}</div>
            </vue-dropzone>
        </b-col>
    </draggable>

</template>

<script>
import Draggable from "vuedraggable"

export default {
    components: {
        Draggable
    },
    props: {
        'value': [String, Array],
        'url': {
            type: String,
            required: true
        },
        'eventType': {
            type: String,
            required: false
        },
        'downloadUrl': {
            type: String,
            required: false
        },
        'acceptedFiles': {
            type: String,
        },
    },
    data() {
        return {
            dropzoneOptions: {
                url: window.apiUrl + this.url,
                maxFilesize: 5,
                previewTemplate: this.template(),
                acceptedFiles: this.acceptedFiles,
                headers: {
                    Authorization: `Bearer ${window.apiToken}`,
                    UserAuthToken: `${window.apiUserToken}`
                },
            },
            filesMap: {},
            dragging: false,
            files: [],
        }
    },
    methods: {
        imagesUrl(file) {
            if (this.eventType && this.eventType == 'download') {
                return '';
            } else {
                return window.apiUrl + '/image/' + file
            }
        },
        fileUrl(name) {
            return window.apiUrl + '/' + this.downloadUrl + '/' + name
        },
        uploaded(file, response) {
            if (!this.files) {
                this.files = []
            }

            this.filesMap[file.upload.uuid] = response.name
            this.files.push(response.name)
            this.$emit('input', this.files);
        },
        errorUpload(file, message, xhr) {
            console.log(file, message, xhr)
            this.$root.showMessage(
                this.$t('common.form.title.error'),
                message.message ? message.message : (message ?? 'Unknown error'),
                'danger'
            )
        },
        removeFile(fileName) {
            this.files = this.files.filter(name => {
                return name != fileName
            })
            this.$emit('input', this.files);
        },
        template: function () {
            return `<div></div>`;
        },
    },
    mounted() {
        this.files = this.value
    },
    watch: {
        'value': {
            handler: function () {
                this.files = this.value
            },
            deep: true
        }
    },
}
</script>

<style>
.files-wrapper {
    background-color: lightgray;
    border-radius: 5px;
    margin-left: 0px;
    margin-right: 0px;
}

.image-preview {
    margin-top: 20px;
}

.image-preview .background {
    position: relative;
    cursor: move;
}

.image-preview .background .remove {
    opacity: 0;
    position: absolute;
    top: 10px;
    right: 10px;
    height: 30px;
    text-align: center;
}

.image-preview .background:hover .remove {
    opacity: 1;
}

.image-preview .background {
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: contain;
    height: 200px;
    border-radius: 5px;
    background-color: white;
}

.download .image-preview .background .file-type {
    position: absolute;
    top: 10px;
    left: 10px;
    opacity: 1;
}

.image-preview .background a {
    color: gray;
    position: absolute;
    bottom: 10px;
    left: 10px;
    background-color: white;
}

.download .image-preview .background {
    height: 100px;

    background-color: white;
}

#customdropzone {
    background-color: lightcyan;
    border-radius: 5px;
    margin-top: 20px;
    padding: 70px 20px;
}

.download #customdropzone {
    height: 100px;
    padding: 25px;
}
</style>
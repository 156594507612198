import {commonTableFetch} from "@/store/common-functions";

function getDefaultState() {
    return {
        contactPersons: {},
        contactPerson: {
            id: null,
            customerId: null,
            salutation: '',
            addressType: '',
            firstname: '',
            lastname: '',
            position: '',
            street: '',
            postCode: '',
            city: '',
            countryCode: 'DE',
            email: '',
            website: '',
            phone: '',
            mobile: '',
            birthDate: null,
            fax: '',
            facebook: '',
            linkedIn: '',
            xing: '',
        },
        contactPersonsTable: {},
    }
}

const getters = {
    getContactPerson(state) {
        return state.contactPerson
    },
    getContactPersons(state) {
        return state.contactPersons
    },
    getContactPersonsTable: state => state.contactPersonsTable,
}

const actions = {
    deleteContactPerson({dispatch}, {contactPersonId, customerId}) {
        return new Promise((resolve) => {
            window.axios.delete(window.apiUrl + '/contact-person/' + contactPersonId).then((response) => {
                dispatch('fetchContactPersons', customerId)
                resolve(response);
            })
        })
    },
    fetchContactPersons({commit, _}, params) {
        return commonTableFetch(commit, '/contact-persons', 'setContactPersonsTable', params)
    },
    fetchContactPersonsList({commit}, customerId) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/contact-persons/' + customerId, {'skip_loading': true})
                .then(response => {
                    commit('setContactPersons', {data: response.data})

                    resolve(response)
                })
        })
    },
    fetchContactPerson({commit}, {id, customerId}) {
        return new Promise((resolve) => {
            if (id) {
                window.axios.get(window.apiUrl + '/contact-person/show/' + id, {'skip_loading': true})
                    .then(response => {
                        commit('setContactPerson', {...response.data})
                        resolve(response)
                    })
            } else {
                commit('setContactPerson', {...getDefaultState().contactPerson, customerId})
                resolve()
            }
        })
    },
    saveContactPerson({commit, dispatch}, contactPerson) {
        return new Promise((resolve) => {
            if (contactPerson.id) {
                window.axios.put(window.apiUrl + '/contact-person/' + contactPerson.id, contactPerson, {'skip_loading': true})
                    .then(response => {
                        commit('setContactPerson', response.data)
                        dispatch('fetchContactPersons', response.data.customerId)

                        resolve(response);
                    })
            } else {
                window.axios.post(window.apiUrl + '/contact-person', contactPerson, {'skip_loading': true})
                    .then(response => {
                        commit('setContactPerson', response.data)
                        dispatch('fetchContactPersons', response.data.customerId)

                        resolve(response);
                    })
            }
        })
    }
}

const mutations = {
    setContactPerson(state, contactPerson) {
        state.contactPerson = {...getDefaultState().contactPerson, ...contactPerson}
    },
    setContactPersons(state, {data}) {
        state.contactPersons = data
    },
    setContactPersonsTable(state, data) {
        state.contactPersonsTable = data
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
function getDefaultState() {
    return {
        dumps: [],
    }
}

const getters = {
    getDumps: state => state.dumps,
}

const actions = {
    fetchDumps({commit}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/settings/dumps', {'skip_loading': true})
                .then(response => {
                    commit('setDumps', response.data)
                    resolve(response)
                })
        })
    },
    createDump({commit}) {
        return new Promise((resolve) => {
            window.axios.post(window.apiUrl + '/settings/dumps', {'skip_loading': true})
                .then(response => {
                    resolve(response)
                })
        })
    },
    deleteDump({dispatch}, id) {
        return new Promise((resolve) => {
            window.axios.delete(window.apiUrl + '/settings/dumps/' + id).then((response) => {
                resolve(response);
            })
        })
    },
}

const mutations = {
    setDumps(state, dumps) {
        state.dumps = dumps.dumps
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
import {commonStoreFetch, commonStoreUpdate} from "@/store/common-functions";

function getDefaultState() {
    return {
        invoice_prepare: {},
        preview: {},
        invoiceStatuses: {},
    }
}

const getters = {
    getInvoicePrepare: state => state.invoice_prepare,
    getPreview: state => state.preview,
    getInvoiceStatuses: state => state.invoiceStatuses
}

const actions = {
    fetchInvoiceStatuses({commit}, id) {
        return commonStoreFetch(commit, '/invoice/' + id + '/invoice-statuses', 'setInvoiceStatuses')
    },
    updateStatus(_, invoice) {
        return commonStoreUpdate('/invoice/' + invoice.id + '/update-status', {status_id: invoice.statusId})
    },
    fetchInvoicePrepare({commit, _}, {id, type}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl
                + '/invoice/prepare/' + id + '/' + type
            ).then(response => {
                commit('setInvoicePrepare', response.data)
                resolve()
            })
        })
    },
    fetchInvoicePreview({commit, _}, {id, type}) {
        return commonStoreFetch(commit, '/invoice/preview/' + id + '/' + type, 'setPreview',true)
    },
    generateInvoice({commit, _}, data) {
        return new Promise((resolve) => {
            window.axios.post(window.apiUrl + '/invoice/prepare/' + (data.id ? data.id : 0), data).then(response => {
                resolve(response)
            })
        })
    },
    generateDocument({commit, _}, data) {
        return new Promise((resolve) => {
            window.axios.post(window.apiUrl + '/invoice/generate/' + (data.id), data).then(response => {
                resolve(response)
            })
        })
    },
    saveHeaderFooter({commit, _}, data) {
        return new Promise((resolve) => {
            window.axios.post(window.apiUrl + '/invoice/header-footer/' + (data.id), data).then(response => {
                resolve(response)
            })
        })
    },
    generateCombine({commit, _}, ids) {
        return new Promise((resolve, reject) => {
            window.axios.post(window.apiUrl + '/invoice/generate-combine', {ids}, {'skip_loading': true})
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
    generateMergedFiles({commit, _}, data) {
        return new Promise((resolve, reject) => {
            window.axios.post(window.apiUrl + '/invoice/generate-merged-files', {data}, {'skip_loading': true})
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
}

const mutations = {
    setInvoicePrepare(state, data) {
        state.invoice_prepare = data
    },
    setPreview(state, data) {
        state.preview = data
    },
    setInvoiceStatuses(state, invoiceStatuses) {
        state.invoiceStatuses = invoiceStatuses
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
function getDefaultState() {
    return {
        data: [],
    }
}

const getters = {
    getChartsData: state => state.data,
}

const actions = {
    fetchChartsData({commit}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/charts-data', {'skip_loading': true})
                .then(response => {
                    commit('setChartsData', response.data)
                    resolve(response)
                })
        })
    },
}

const mutations = {
    setChartsData(state, response) {
        state.data = response
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
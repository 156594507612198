import {
    commonStoreSave,
} from "@/store/common-functions";

function getDefaultState() {
    return {
        channelArticle: {},
        articlesMap: null,
    }
}

const getters = {
    getChannelArticle: state => state.channelArticle,
    getArticlesMap: state => state.articlesMap,
}

const actions = {
    fetchArticlesMap({commit}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/channel-article/check-map', {'skip_loading': true})
                .then(response => {
                    commit('setArticlesMap', response.data)
                    resolve(response)
                })
        })
    },
    saveArticlesMap({commit}, insurance) {
        return new Promise((resolve, reject) => {
            const formData = new FormData();

            Object.entries(insurance).forEach(([key, value]) => {
                formData.append(key, value);
            });

            window.axios.post(window.apiUrl + '/channel-article/upload-map', formData, {
                'skip_loading': true,
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            })
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
    fetchChannelArticle({commit}, id) {
        return new Promise((resolve, reject) => {
            window.axios.get(window.apiUrl + '/channel-article/' + id, {'skip_loading': true})
                .then(response => {
                    commit('setChannelArticle', response.data)
                    resolve(response)
                })
        })
    },
    updateAttributes({commit}, form) {
        return new Promise((resolve, reject) => {
            window.axios.post(window.apiUrl + '/channel-article/attributes', form, {'skip_loading': true})
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
    updatePrice({commit}, data) {
        return new Promise((resolve, reject) => {
            window.axios.post(window.apiUrl + '/channel-article/change-price', data, {'skip_loading': true})
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
    assign(_, data) {
        return commonStoreSave('/channel-article/assign', data)
    },
    deleteChannelArticles({commit}, data) {
        return new Promise((resolve, reject) => {
            window.axios.post(window.apiUrl + '/channel-article/delete-selected', data, {'skip_loading': true})
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
    saveChannelArticle({commit}, article) {
        return new Promise((resolve, reject) => {
            window.axios.post(window.apiUrl + '/channel-article/' + article.id, article, {'skip_loading': true})
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
}

const mutations = {
    setChannelArticle(state, data) {
        state.channelArticle = data.article
    },
    setArticlesMap(state, data) {
        state.articlesMap = data
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
import {commonTableFetch} from "@/store/common-functions";

function getDefaultState() {
    return {
        footer: null,
        footers: [],
        footersTable: {}
    }
}

const getters = {
    getFooter: state => state.footer,
    getFooters: state => state.footers,
    getFootersTable: state => state.footersTable,
}

const actions = {
    fetchFooters({commit, _}, params) {
        return commonTableFetch(commit, '/footers', 'setFootersTable', params)
    },
    fetchFooter({commit}, id) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/footers/' + (id ? id : 0), {'skip_loading': true})
                .then(response => {
                    commit('setFooter', {...response.data, id})
                    resolve(response)
                })
        })
    },
    saveFooter({commit}, footer) {
        return new Promise((resolve, reject) => {
            window.axios.post(window.apiUrl + '/footers/' + (footer.id ? footer.id : 0), footer, {'skip_loading': true})
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
    deleteFooter({dispatch}, id) {
        return new Promise((resolve) => {
            window.axios.delete(window.apiUrl + '/footers/' + id).then((response) => {
                resolve(response);
            })
        })
    },
}

const mutations = {
    setFooter(state, response) {
        state.footer = response.footer
    },
    setFooters(state, footers) {
        state.footers = footers.footers
    },
    setFootersTable(state, data) {
        state.footersTable = data
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
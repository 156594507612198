<script>
const CancelConfirm = {
    install(Vue, options) {
        Vue.prototype.$cancelConfirm = function (action, payload, callback = null) {
            this.$confirm(
                {
                    message: this.$t('common.confirmations.cancel'),
                    button: {
                        no: this.$t('common.button.no'),
                        yes: this.$t('common.button.yes')
                    },
                    callback: confirm => {
                        if (confirm) {
                            this.$store.dispatch(action, payload).then((response) => {
                                if (response.status == 204 || response.status == 200) {
                                    if (response.data.batch_group) {
                                        this.$root.$children[0].showJobsToast(response.data.batch_group)
                                    } else {
                                        this.$root.showMessage(this.$t('common.toasts.canceled'), this.$t('common.toasts.canceled'), 'success')
                                    }

                                    if (callback) {
                                        callback()
                                    }
                                }
                            })

                        }
                    }
                }
            )
        }
    }
};

export default CancelConfirm;
</script>
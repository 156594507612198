<template>
    <div>
        <CCard>
            <CCardHeader class="page-header">
                <strong> {{ $t('settings.title.settings') }}</strong>
            </CCardHeader>
            <CCardBody>
                <b-tabs lazy content-class="mt-3">
                    <b-tab v-for="(tab,index) in tabs"
                           :key="tab.key"
                           v-if="tab.enabled"
                           :active="activeTab==tab.key"
                    >
                        <template slot="title">
                            <font-awesome-icon :icon="tab.icon"></font-awesome-icon>
                            {{ tab.title }}
                            <b-badge v-if="tab.new" href="#" variant="warning">New</b-badge>
                        </template>
                        <component v-bind:is="tab.tab"></component>
                    </b-tab>
                </b-tabs>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'


export default {
    name: 'Settings',
    methods: {
        ...mapGetters('CommonData', ['getModules', 'getMe']),

    },
    mounted() {
        if (localStorage.getItem('firstLogin') === 'true') {
            this.$route.params.tab = 'profiles'
        }

        if (this.$route.params.tab) {
            this.activeTab = this.$route.params.tab
        }
        if (this.$route.query.tab) {
            this.activeTab = this.$route.query.tab

        }
    },
    data() {
        let tabs = []
        
        return {
            'activeTab': 'general',
            'tabs': tabs,
        }
    }
}
</script>
function getDefaultState() {
    return {
        news_templates: [],
        news_template: {},
        placeholders: [],
    }
}

const getters = {
    getNewsTemplates: state => state.news_templates,
    getNewsTemplate: state => state.news_template,
    getNewsPlaceholders: state => state.placeholders,
}

const actions = {
    fetchTemplates({commit}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/news-template', {'skip_loading': true})
                .then(response => {
                    commit('setTemplates', response.data)
                    resolve(response)
                })
        })
    },
    fetchTemplate({commit}, id) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/news-template/' + (id ? id : 0), {'skip_loading': true})
                .then(response => {
                    commit('setTemplate', response.data)
                    resolve(response)
                })
        })
    },
    deleteTemplate({dispatch}, id) {
        return new Promise((resolve) => {
            window.axios.delete(window.apiUrl + '/news-template/' + id).then((response) => {
                resolve(response);
            })
        })
    },
    saveTemplate({commit}, template) {
        return new Promise((resolve, reject) => {
            window.axios.post(window.apiUrl + '/news-template', template, {'skip_loading': true})
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
    sendTestNews({commit, _}, data) {
        return new Promise((resolve) => {
            window.axios.post(window.apiUrl + '/news-template/test', data, {'skip_loading': true})
                .then(response => {
                    resolve(response)
                })
        })
    },
}

const mutations = {
    setTemplates(state, response) {
        state.news_templates = response.templates
    },
    setTemplate(state, response) {
        state.news_template = response.template
        state.placeholders = response.placeholders
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
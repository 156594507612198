import {
    commonStoreFetch,
    commonStoreSave,
    commonStoreDelete
} from "@/store/common-functions";

function getDefaultState() {
    return {
        category: {},
        categories: [],
        categoryOptions: [],
        categoryOptionsTree: [],
        configurableCategories: [],
        configurableDefaultCategories: [],
    }
}

const getters = {
    getCategory: state => state.category,
    getCategories: state => state.categories,
    getCategoryOptions: state => state.categoryOptions,
    getCategoryOptionsTree: state => state.categoryOptionsTree,
    getConfigurableCategories: state => state.configurableCategories,
    getCategoriesWithAttributes: state => state.categoryWithAttributes,
    getConfigurableDefaultCategories: state => state.configurableDefaultCategories,
}

const actions = {
    fetchCategory({commit}, id) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/category/show/' + id, {'skip_loading': true})
                .then(response => {
                    commit('setCategory', response.data)
                    resolve(response)
                })
        })
    },
    fetchCategories({commit}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/category', {'skip_loading': true})
                .then(response => {
                    commit('setCategories', response.data)
                    resolve(response)
                })
        })
    },
    fetchSubCategories({commit}, parent_id) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/category/sub/' + parent_id, {'skip_loading': true})
                .then(response => {
                    resolve(response.data)
                })
        })
    },
    fetchOptions({commit}, allowRoot = 0) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/category/options/' + allowRoot, {'skip_loading': true})
                .then(response => {
                    commit('setCategoryOptions', response.data)
                    resolve(response)
                })
        })
    },
    saveCategory({commit}, category) {
        return commonStoreSave('/category', category)
    },
    fetchOptionsTree({commit}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/category/optionstree', {'skip_loading': true})
                .then(response => {
                    commit('setCategoryOptionsTree', response.data)
                    resolve(response)
                })
        })
    },
    fetchConfigurable({commit}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/category/configurable', {'skip_loading': true})
                .then(response => {
                    commit('setConfigurableCategories', response.data)
                    resolve(response)
                })
        })
    },
    deleteCategory({dispatch}, ids) {
        let idsString = ids.join(",")
        return commonStoreDelete('/category/' + idsString)
    },
}

const mutations = {
    setCategory(state, response) {
        state.category = response.category
    },
    setCategories(state, response) {
        state.categories = response
    },
    setCategoryOptions(state, response) {
        state.categoryOptions = response.categories
    },
    setCategoryOptionsTree(state, response) {
        state.categoryOptionsTree = response.categories
    },
    setConfigurableCategories(state, response) {
        state.configurableCategories = response.configurable_categories
        state.configurableDefaultCategories = response.configurable_default_categories
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
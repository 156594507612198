function getDefaultState() {
    return {
        reportsTable: {},
        report: {
            id: null,
            customer_id: null,
        },
    }
}

const getters = {
    getReport: state => state.report,
    getReportsTable: state => state.reportsTable,
}

const actions = {
    deleteReport({dispatch}, {id, customerId}) {
        return new Promise((resolve) => {
            window.axios.delete(window.apiUrl + '/crm/customer/reports/' + id).then((response) => {
                resolve(response);
            })
        })
    },
    fetchReports({commit, _}, params) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl
                + '/crm/customer/reports/' + params.customerId
                + '?per_page=' + params.perPage
            ).then(response => {
                commit('setReportsTable', response.data)
                resolve()
            })
        })
    },
    fetchReport({commit}, {id, customerId}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/crm/customer/reports/' + customerId + '/' + (id ?? 0), {'skip_loading': true})
                .then(response => {
                    commit('setReport', {...getDefaultState().report, ...response.data})
                    resolve(response)
                })
        })
    },
    saveReport({commit, dispatch}, report) {
        return new Promise((resolve) => {
            if (report.id) {
                window.axios.put(window.apiUrl + '/crm/customer/reports/' + report.customer_id + '/' + report.id, report, {'skip_loading': true})
                    .then(response => {
                        commit('setReport', response.data)
                        resolve(response);
                    })
            } else {
                window.axios.post(window.apiUrl + '/crm/customer/reports/' + report.customer_id, report).then(response => {
                    commit('setReport', response.data)
                    resolve(response);
                })
            }
        })
    }
}

const mutations = {
    setReport(state, report) {
        state.report = {...getDefaultState().report, ...report}
    },
    setReportsTable(state, data) {
        state.reportsTable = data
    }
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
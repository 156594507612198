import axios from 'axios'

function getDefaultState() {
    return {
        attributes: [],
        attributesFiltered: [],
    }
}

const getters = {
    getAttributes: state => state.attributes,
    getAttributesFiltered: state => state.attributesFiltered,
}

const actions = {
    fetchAttributes({commit}, type) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/mass-update/attributes/' + type, {'skip_loading': true})
                .then(response => {
                    commit('setAttributes', response.data)
                    resolve(response)
                })
        })
    },
    fetchAttributesFiltered({commit}, data) {
        return new Promise((resolve) => {
            window.axios.post(window.apiUrl + '/mass-update/attributes/' + data.attribute_type, data, {'skip_loading': true})
                .then(response => {
                    commit('setAttributesFiltered', response.data)
                    resolve(response)
                })
        })
    },
    updateAttributes({commit}, form) {
        return new Promise((resolve, reject) => {
            window.axios.post(window.apiUrl + '/mass-update/attributes', form, {'skip_loading': true})
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
}

const mutations = {
    setAttributes(state, data) {
        state.attributes = data.attributes
    },
    setAttributesFiltered(state, data) {
        state.attributesFiltered = data.attributes
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
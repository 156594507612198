import {buildFormData, commonTableFetch} from "@/store/common-functions";

function getDefaultState() {
    return {
        paymentmethods: [],
        kvapaymentmethods: [],
        paymentmethod: {},
    }
}

const getters = {
    getPaymentmethods: state => state.paymentmethods,
    getKvaPaymentmethods: state => state.kvapaymentmethods,
    getPaymentmethod: state => state.paymentmethod,
}

const actions = {
    fetchPaymentmethods({commit}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/paymentmethod', {'skip_loading': true})
                .then(response => {
                    commit('setPaymentmethods', response.data)
                    resolve(response)
                })
        })
    },
    fetchKvaPaymentmethods({commit}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/kvapaymentmethod', {'skip_loading': true})
                .then(response => {
                    commit('setKvaPaymentmethods', response.data)
                    resolve(response)
                })
        })
    },
    fetchPaymentmethod({commit}, id) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/paymentmethod/' + (id ? id : 0), {'skip_loading': true})
                .then(response => {
                    commit('setPaymentmethod', response.data)
                    resolve(response)
                })
        })
    },
    deletePaymentmethod({dispatch}, id) {
        return new Promise((resolve) => {
            window.axios.delete(window.apiUrl + '/paymentmethod/' + id).then((response) => {
                resolve(response);
            })
        })
    },
    savePaymentmethod({commit}, paymentmethod) {
        return new Promise((resolve, reject) => {
            window.axios.post(window.apiUrl + '/paymentmethod', paymentmethod, {'skip_loading': true})
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
    setPaymentmethodMap({commit}, params) {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            buildFormData(formData, params)

            window.axios.post(window.apiUrl + '/paymentmethod/save-map', formData, {
                'skip_loading': true,
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            })
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
}

const mutations = {
    setPaymentmethods(state, response) {
        state.paymentmethods = response.paymentmethods
    },
    setKvaPaymentmethods(state, response) {
        state.kvapaymentmethods = response.paymentmethods
    },
    setPaymentmethod(state, response) {
        state.paymentmethod = response.paymentmethod
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
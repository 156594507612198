<script>
import axios from "axios";

const Helpers = {
    install(Vue, options) {
        Vue.prototype.$openExport = function (type, tableOptions, filter) {
            this.$root.$children[0].openModal('export-modal', {
                type: type,
                tableOptions: tableOptions,
                filter: filter,
            }, this.refreshTable, {width: '800px'})
        }
    }
};

export default Helpers;
</script>
<template>
    <div>
        <div v-if="isSidebarOpen && !isDraggable" class="custom-backdrop" @click="close"></div>
        <b-sidebar @shown="sidebarShown" ref="sidebar" title="test"
                   :width="typeof width === 'string' ? width : (width+'%')"
                   :id="id"
                   :z-index="zIndex" right no-close-on-esc shadow>
            <template #header>
                <slot name="sidebar-title">
                    <strong :class="titleClass" style="font-size: 14px">{{ title }}</strong>
                </slot>
                <b-button-close v-if="showClose" style="margin-left: auto;margin-right: unset"
                                @click="close"></b-button-close>
            </template>
            <template>
                <template v-if="disableWrapper">
                    <slot></slot>
                </template>
                <div v-else class="px-2 py-1">
                    <div v-if="openedModals.length">
                        <div v-for="(modal,index) in openedModals"
                             :key="index"
                             class="card bg-warning mb-1"
                        >
                            <div class="card-body">
                                <div class=" text-uppercase font-weight-bold">
                                    <font-awesome-icon class="mr-2" icon="info"/>
                                    {{ $t('common.toasts.form_another_user') }} {{ modal.user.id }} {{
                                        modal.user.email
                                    }}
                                    ({{ modal.last_activity | formatDate('DD.MM.YYYY HH:mm:ss') }})
                                </div>
                            </div>
                        </div>
                    </div>
                    <slot></slot>
                </div>
            </template>
        </b-sidebar>
    </div>
</template>

<script>
export default {
    name: 'zw-sidebar',
    props: {
        'title': {
            type: String,
        },
        'titleClass': {
            type: String,
            default: ''
        },
        'disableWrapper': {
            type: Boolean,
            default: false
        },
        'showClose': {
            type: Boolean,
            default: true
        },
        'callbackClose': {
            type: Function,
        },
        isDraggable: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            width: 95,
            zIndex: 1000,
            id: '',
            name: '',
            payload: null,
            interval: null,
            openedModals: [],
            editedSidebars: {},
            isSidebarOpen: false,
        }
    },
    methods: {
        close() {
            clearInterval(this.interval)
            this.interval = null

            if (this.callbackClose) {
                this.callbackClose()
            }
            clearInterval(this.interval)
            this.interval = null

            let result = this.$root.$children[0].closeModal(this.$refs['sidebar'], true)
            if (result) {
                this.isSidebarOpen = false;
            }
        },
        sidebarShown() {
            this.isSidebarOpen = true;
            this.modalOpened()
            this.interval = window.setInterval(() => {
                this.modalOpened()
            }, 30000)

            this.$emit('shown')
        },
        modalOpened() {
            if (this.name) {
                window.axios.post(
                    window.apiUrl + '/common-data/modals',
                    {modal: this.name, params: this.payload},
                    {'skip_loading': true}
                )
                    .then(response => {
                        if (response.data.opened) {
                            this.openedModals = response.data.opened
                        } else {
                            this.openedModals = []
                        }
                    })
            }
        }
    }
    ,
    mounted() {
        this.$nextTick(() => {
            this.$root.$children[0].openModalCallback()
        })
    }
}
</script>
<style>
.custom-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 2001; /* Ensure it covers the sidebar */
}
</style>
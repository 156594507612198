import axios from "axios";
import {commonStoreDelete, commonStoreSave, commonStoreUpdate, commonTableFetch} from "@/store/common-functions";

function getDefaultState() {
    return {
        positionsTable: {},
        returnPositionsTable: {},
        position: {},
    }
}

const getters = {
    getPositionsTable: state => state.positionsTable,
    getReturnPositionsTable: state => state.returnPositionsTable,
    getPosition: state => state.position,
}

const actions = {
    fetchPositions({commit, _}, params) {
        return commonTableFetch(commit, '/offering-positions/' + params.offering_id, 'setPositionsTable', params)
    },
    fetchReturnPositions({commit, _}, params) {
        return commonTableFetch(commit, '/offering-return-positions/' + params.offering_id, 'setReturnPositionsTable', params)
    },
    fetchPosition({commit}, id) {
        return new Promise((resolve) => {
            axios.get(window.apiUrl + '/offering-position/' + id).then(response => {
                commit('setPosition', response.data)
                resolve(response)
            })
        })
    },
    duplicatePosition(_, id) {
        return commonStoreSave('/offering-position/duplicate/' + id)
    },
    createCategory(_, data) {
        return commonStoreSave('/offering-category/create/' + data.offering_id, data)
    },
    deleteCategory(_, id) {
        return commonStoreDelete('/offering-category/delete/' + id)
    },
    saveCategory(_, data) {
        return commonStoreSave('/offering-category/update/' + data.id, data)
    },
    reorderPositions(_, data) {
        return commonStoreSave('/offering-position/reorder', data)
    },
    saveOfferingPosition({commit}, position) {
        return commonStoreUpdate('/offering/' + position.offeringId + '/position/' + position.id, position)
    },
}

const mutations = {
    setPositionsTable(state, data) {
        state.positionsTable = data
    },
    setReturnPositionsTable(state, data) {
        state.returnPositionsTable = data
    },
    setPosition(state, data) {
        state.position = data.position
    },
    setPositions(state, data) {
        state.positions = data.positions
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
import {commonTableFetch} from "@/store/common-functions";

function getDefaultState() {
    return {
        usersList: [],
        usersTable: {},
        instanceUsersTable: {},
        user: {},
    }
}

const getters = {
    getUsersList: state => state.usersList,
    getUsersTable: state => state.usersTable,
    getInstanceUsersTable: state => state.instanceUsersTable,
    getUser: state => state.user,
}

const actions = {
    fetchUsersList({commit, _}, params) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/users/list', {'skip_loading': true})
                .then(response => {
                    commit('setUsersList', response.data)
                    resolve()
                })
        })
    },
    saveNewPassword({commit, _}, data) {
        return new Promise((resolve) => {
            window.axios.post(window.apiUrl + '/users/new-password', data, {'skip_loading': true})
                .then(response => {
                    resolve(response)
                })
        })
    },
    checkSmtp({commit, _}, data) {
        return new Promise((resolve) => {
            window.axios.post(window.apiUrl + '/users/test-smtp', data, {'skip_loading': true})
                .then(response => {
                    resolve(response)
                })
        })
    },
    fetchUsers({commit, _}, params) {
        return commonTableFetch(commit, '/users', 'setUsersTable', params)
    },
    fetchInstanceUsers({commit, _}, params) {
        return commonTableFetch(commit, '/instance/users/' + params.filter.instance_id, 'setInstanceUsers', params)
    },
    fetchUser({commit}, id) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/users/' + (id ? id : 0), {'skip_loading': true})
                .then(response => {
                    commit('setUser', response.data)
                    resolve(response)
                })
        })
    },
    deleteUser({dispatch}, id) {
        return new Promise((resolve) => {
            window.axios.delete(window.apiUrl + '/users/' + id).then((response) => {
                resolve(response);
            })
        })
    },
    saveUser({commit}, role) {
        return new Promise((resolve, reject) => {
            window.axios.post(window.apiUrl + '/users', role, {'skip_loading': true})
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
}

const mutations = {
    setUsersList(state, usersList) {
        state.usersList = usersList
    },
    setUsersTable(state, data) {
        state.usersTable = data
    },
    setInstanceUsers(state, data) {
        state.instanceUsersTable = data
    },
    setUser(state, response) {
        state.user = response.user
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
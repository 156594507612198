import Vue from 'vue'
import Vuex from 'vuex'

import I18NStore from './modules/i18n'
import Sales from './modules/sales'
import Ckeditor from './modules/ckeditor'
import Customer from './modules/customer'
import News from './modules/news'
import ContractSettings from './modules/contract-settings'
import CustomerContract from './modules/customer-contract'
import Templates from './modules/templates'
import ContactPerson from './modules/contact-person'
import CommonData from './modules/common-data'
import Offering from './modules/offering'
import Import from './modules/import'
import Crm from './modules/crm'
import Users from './modules/users'
import Profiles from './modules/profiles'
import Todo from './modules/todo'
import CustomerReports from './modules/customer-reports'
import Articles from './modules/articles'
import CustomerCategories from './modules/customer-categories.js'
import Attributes from './modules/attributes'
import Headers from './modules/headers'
import Footers from './modules/footers'
import Dumps from './modules/dumps'
import Vats from './modules/vats'
import LagerTypes from './modules/lager-type'
import Warehouses from './modules/warehouses'
import IncomingInvoices from './modules/incoming-invoices'
import CashInvoices from './modules/cash-invoices'
import Invoices from './modules/invoices'
import Deposits from './modules/deposits'
import Transfers from './modules/transfers'
import Insurance from './modules/insurance'
import Cotravelers from './modules/cotravelers'
import Settings from './modules/settings'
import Category from './modules/category'
import Export from './modules/export'
import Status from './modules/status'
import EntityStatus from './modules/entity-status'
import Paymentmethod from './modules/paymentmethod'
import TodosAction from './modules/todos-action'
import Kbkonto from './modules/kbkonto'
import Kbbuchung from './modules/kbbuchung'
import Modules from './modules/modules'
import Hotels from './modules/hotels'
import CustomFields from './modules/custom-fields'
import Invoice from './modules/invoice'
import Dashboard from './modules/dashboard'
import Multichannel from './modules/multichannel'
import CommonTable from './modules/common-table'
import Jobs from './modules/jobs'
import Channel from './modules/channel'
import Shipments from './modules/shipments'
import Roles from './modules/roles'
import ChannelArticle from './modules/channel-article'
import Stock from './modules/stock'
import Supply from './modules/supply'
import ApiLogs from './modules/api-logs'
import ShippingRule from './modules/shipping-rule'
import MassUpdate from './modules/mass-update'
import EmailTemplate from './modules/email-templates'
import NewsTemplate from './modules/news-templates'
import Positions from './modules/positions'
import Mail from './modules/mail'
import Barcode from './modules/barcode'
import Workflow from './modules/workflow'
import BankTransfers from './modules/bank-transfers'
import ProfitAndLoss from './modules/profit_and_loss'

Vue.use(Vuex)

const state = {
    sidebarShow: 'responsive',
    sidebarMinimize: false,
    lastFetchToken: null,
}

const mutations = {
    toggleSidebarDesktop(state) {
        const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
        state.sidebarShow = sidebarOpened ? false : 'responsive'
    },
    toggleSidebarMobile(state) {
        const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
        state.sidebarShow = sidebarClosed ? true : 'responsive'
    },
    set(state, [variable, value]) {
        state[variable] = value
    },
    setFetchToken(state, data) {
        state.lastFetchToken = data
    }
}

const getters = {
    getSidebarShow: state => state.sidebarShow,
    getLastFetchToken: state => state.lastFetchToken,
}


export default new Vuex.Store({
    state: state,
    mutations: mutations,
    getters: getters,
    actions: {},
    modules: {
        I18NStore,
        Sales,
        Ckeditor,
        Customer,
        CustomerContract,
        ContractSettings,
        News,
        Templates,
        ContactPerson,
        CommonData,
        Offering,
        Import,
        Crm,
        Users,
        Profiles,
        Todo,
        CustomerReports,
        Articles,
        CustomerCategories,
        Attributes,
        Headers,
        Footers,
        Dumps,
        Vats,
        LagerTypes,
        Warehouses,
        Deposits,
        IncomingInvoices,
        Invoices,
        Insurance,
        Cotravelers,
        Settings,
        Category,
        Export,
        Status,
        EntityStatus,
        Paymentmethod,
        TodosAction,
        Kbkonto,
        Kbbuchung,
        Transfers,
        Modules,
        Hotels,
        CustomFields,
        Invoice,
        Dashboard,
        Multichannel,
        CommonTable,
        Jobs,
        Channel,
        Shipments,
        Roles,
        ChannelArticle,
        Stock,
        Supply,
        ApiLogs,
        ShippingRule,
        EmailTemplate,
        NewsTemplate,
        MassUpdate,
        Positions,
        Mail,
        Barcode,
        Workflow,
        CashInvoices,
        BankTransfers,
        ProfitAndLoss,
    }
})
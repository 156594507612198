function getDefaultState() {
    return {
        sources: [],
        formats: [],
    }
}

const getters = {
    getSources: state => state.sources,
    getFormats: state => state.formats,
}

const actions = {
    doExport({commit, _}, params) {
        return new Promise((resolve, reject) => {
            window.axios.post(window.apiUrl + '/export',
                {
                    ...{
                        type: params.type,
                        tableFormat: params.tableFormat,
                        filter: params.filter,
                        per_page: params.perPage,
                        page: params.currentPage,
                        'export': params.export,
                    },
                    ...params.additionalOptions
                },
                {'skip_loading': true}
            )
                .then(response => {
                    resolve(response)
                }, () => {
                    reject()
                })
        })
    },
    fetchOptions({commit, _}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/export/options', {'skip_loading': true})
                .then(response => {
                    commit('setOptions', response.data)
                    resolve(response)
                })
        })
    },
}

const mutations = {
    setOptions(state, options) {
        state.sources = options.sources
        state.formats = options.formats
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
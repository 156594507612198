import {commonTableFetch} from "@/store/common-functions";

function getDefaultState() {
    return {
        invoicesTable: {},
        invoice: {},
    }
}

const getters = {
    getInvoicesTable: state => state.invoicesTable,
}

const actions = {
    fetchInvoices({commit, _}, params) {
        return commonTableFetch(commit, '/invoices', 'setInvoicesTable', params)
    },
    markPaidInvoices({dispatch}, data) {
        return new Promise((resolve) => {
            let queryString = data.ids.map((id) => {
                return 'ids[]=' + id
            }).join('&');
            queryString += '&paid_date=' + data.paid_date + '&paid_sum=' + data.paid_sum;
            window.axios.post(window.apiUrl + '/invoices/paid?' + queryString).then((response) => {
                resolve(response);
            })
        })
    },
    markUnpaidInvoices({dispatch}, ids) {
        return new Promise((resolve) => {
            let queryString = ids.map((id) => {
                return 'ids[]=' + id
            }).join('&');
            window.axios.post(window.apiUrl + '/invoices/unpaid?' + queryString).then((response) => {
                resolve(response);
            })
        })
    },
    cancelInvoices({dispatch}, data) {
        return new Promise((resolve) => {
            let queryString = data.ids.map((id) => {
                return 'ids[]=' + id
            }).join('&');
            queryString += '&reason=' + data.reason;
            window.axios.post(window.apiUrl + '/invoices/cancel?' + queryString).then((response) => {
                resolve(response);
            })
        })
    },
    uncancelInvoices({dispatch}, ids) {
        return new Promise((resolve) => {
            let queryString = ids.map((id) => {
                return 'ids[]=' + id
            }).join('&');
            window.axios.post(window.apiUrl + '/invoices/uncancel?' + queryString).then((response) => {
                resolve(response);
            })
        })
    },
}

const mutations = {
    setInvoicesTable(state, data) {
        state.invoicesTable = data
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
function getDefaultState() {
    return {
        rolesList: [],
        roles: [],
        role: {},
    }
}

const getters = {
    getRolesList: state => state.rolesList,
    getRoles: state => state.roles,
    getRole: state => state.role,
}

const actions = {
    fetchRolesList({commit, _}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/roles/list', {'skip_loading': true})
                .then(response => {
                    commit('setRolesList', response.data)
                    resolve()
                })
        })
    },
    fetchRoles({commit}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/roles', {'skip_loading': true})
                .then(response => {
                    commit('setRoles', response.data)
                    resolve(response)
                })
        })
    },
    fetchRole({commit}, id) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/roles/' + (id ? id : 0), {'skip_loading': true})
                .then(response => {
                    commit('setRole', response.data)
                    resolve(response)
                })
        })
    },
    deleteRole({dispatch}, id) {
        return new Promise((resolve) => {
            window.axios.delete(window.apiUrl + '/roles/' + id).then((response) => {
                resolve(response);
            })
        })
    },
    saveRole({commit}, role) {
        return new Promise((resolve, reject) => {
            window.axios.post(window.apiUrl + '/roles', role, {'skip_loading': true})
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
}

const mutations = {
    setRolesList(state, response) {
        state.rolesList = response
    },
    setRoles(state, response) {
        state.roles = response.roles
    },
    setRole(state, response) {
        state.role = response.role
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
import {commonStoreDelete, commonStoreFetch, commonStoreSave, commonTableFetch} from "@/store/common-functions";

function getDefaultState() {
    return {
        field: {},
        fieldsTable: [],
        models: [],
        modelFields: [],
    }
}

const getters = {
    getField: state => state.field,
    getFieldsTable: state => state.fieldsTable,
    getModels: state => state.models,
    getModelFields: state => state.modelFields,
}

const actions = {
    fetchFields({commit, _}, params) {
        return commonTableFetch(commit, '/custom-fields', 'setFieldsTable', params)
    },
    fetchField({commit}, {id}) {
        return commonStoreFetch(commit, '/custom-fields/' + id, 'setField', true)
    },
    fetchFieldsByModel({commit}, {model}) {
        return commonStoreFetch(commit, '/custom-fields/get-by-model/' + model, 'setModelFields', true)
    },
    fetchFieldByName({commit}, {name, model}) {
        return commonStoreFetch(commit, '/custom-fields/get-by-name/' + name + '/' + model, 'setField', true)
    },
    deleteField({dispatch}, id) {
        return commonStoreDelete('/custom-fields/' + id)
    },
    saveField({commit}, field) {
        return commonStoreSave('/custom-fields/' + (field.id ? field.id : 0), field, true)
    },
}

const mutations = {
    setFieldsTable(state, data) {
        state.fieldsTable = data
    },
    setField(state, data) {
        state.field = data.data
        state.models = data.models
    },
    setModelFields(state, data) {
        state.modelFields = data.data
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
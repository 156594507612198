import {commonStoreSave} from "@/store/common-functions";

function getDefaultState() {
    return {
        bookings: [],
        dhl: [],
        bank: [],
        onedrive: [],
        email: [],
        warning: [],
        travel_box: [],
        general: [],
        stock: [],
        documents: [],
        shipping: [],
        status_map: [],
        crm_status_map: [],
    }
}

function buildFormData(formData, data, parentKey) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
    } else {
        const value = data == null ? '' : data;

        formData.append(parentKey, value);
    }
}

const getters = {
    getBookings: state => state.bookings,
    getDhl: state => state.dhl,
    getEmail: state => state.email,
    getWarning: state => state.warning,
    getGeneral: state => state.general,
    getStock: state => state.stock,
    getShipping: state => state.shipping,
    getStatusMap: state => state.status_map,
    getCrmStatusMap: state => state.crm_status_map,
    getCrmStatusMapIds: state => state.crm_status_map_ids,
    getTravelBox: state => state.travelbox,
    getOnedrive: state => state.onedrive,
    getBank: state => state.bank,
    getDocumentsSettings: state => state.documents,
}

const actions = {
    fetchBookings({commit}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/settings/bookings')
                .then(response => {
                    commit('setBookings', response.data)
                    resolve(response)
                })
        })
    },
    fetchShipping({commit}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/settings/shipping')
                .then(response => {
                    commit('setShipping', response.data)
                    resolve(response)
                })
        })
    },
    fetchStatusMap({commit}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/settings/statuses-map')
                .then(response => {
                    commit('setStatusMap', response.data)
                    resolve(response)
                })
        })
    },
    fetchCrmStatusMap({commit}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/settings/crm-statuses-map')
                .then(response => {
                    commit('setCrmStatusMap', response.data)
                    resolve(response)
                })
        })
    },
    fetchCrmStatusMapIds({commit}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/settings/crm-statuses-map-ids')
                .then(response => {
                    commit('setCrmStatusMapIds', response.data)
                    resolve(response)
                })
        })
    },

    fetchDhl({commit}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/settings/dhl')
                .then(response => {
                    commit('setDhl', response.data)
                    resolve(response)
                })
        })
    },
    fetchBank({commit}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/settings/bank')
                .then(response => {
                    commit('setBank', response.data)
                    resolve(response)
                })
        })
    },
    fetchOnedrive({commit}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/settings/onedrive')
                .then(response => {
                    commit('setOnedrive', response.data)
                    resolve(response)
                })
        })
    },
    fetchEmail({commit}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/settings/email')
                .then(response => {
                    commit('setEmail', response.data)
                    resolve(response)
                })
        })
    },
    fetchWarning({commit}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/settings/warning')
                .then(response => {
                    commit('setWarning', response.data)
                    resolve(response)
                })
        })
    },
    fetchDocuments({commit}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/settings/documents')
                .then(response => {
                    commit('setDocuments', response.data)
                    resolve(response)
                })
        })
    },
    fetchTravelBox({commit}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/settings/travel-box')
                .then(response => {
                    commit('setTravelBox', response.data)
                    resolve(response)
                })
        })
    },
    fetchGeneral({commit}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/settings/general')
                .then(response => {
                    commit('setGeneral', response.data)
                    resolve(response)
                })
        })
    },
    fetchStock({commit}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/settings/stock')
                .then(response => {
                    commit('setStock', response.data)
                    resolve(response)
                })
        })
    },
    save({commit}, data) {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            buildFormData(formData, data);

            window.axios.post(window.apiUrl + '/settings', formData, {
                'skip_loading': true,
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            })
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
    anonymizeData({commit}) {
        return commonStoreSave('/settings/anonymize-data', [])
    },
    removeInstance({commit}) {
        return commonStoreSave('/settings/remove-instance', [])
    },
}

const mutations = {
    setBookings(state, response) {
        state.bookings = response.bookings
    },
    setShipping(state, response) {
        state.shipping = response.shipping
    },
    setStatusMap(state, response) {
        state.status_map = response.statuses
    },
    setCrmStatusMap(state, response) {
        state.crm_status_map = response.crm_statuses
    },
    setCrmStatusMapIds(state, response) {
        state.crm_status_map_ids = response.crm_statuses_ids
    },
    setDhl(state, response) {
        state.dhl = response.dhl
    },
    setBank(state, response) {
        state.bank = response.bank
    },
    setOnedrive(state, response) {
        state.onedrive = response.onedrive
    },
    setEmail(state, response) {
        state.email = response.email
    },
    setWarning(state, response) {
        state.warning = response.warning
    },
    setTravelBox(state, response) {
        state.travel_box = response.travel_box
    },
    setGeneral(state, response) {
        state.general = response.general
    },
    setStock(state, response) {
        state.stock = response.stock
    },
    setDocuments(state, response) {
        state.documents = response.documents
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
import {
    commonStoreFetch,
    commonStoreSave,
    commonStoreDelete
} from "@/store/common-functions";

function getDefaultState() {
    return {
        kbkontos: [],
        kbkonto: {},
    }
}

const getters = {
    getKbkontos: state => state.kbkontos,
    getKbkonto: state => state.kbkonto,
}

const actions = {
    fetchKbkontos({commit}) {
        return commonStoreFetch(commit, '/kbkonto', 'setKbkontos')
    },
    fetchKbkonto({commit}, id) {
        return commonStoreFetch(commit, '/kbkonto/' + (id ? id : 0), 'setKbkonto')
    },
    deleteKbkonto({dispatch}, id) {
        return commonStoreDelete('/kbkonto/' + id)
    },
    saveKbkonto({commit}, kbkonto) {
        return commonStoreSave('/kbkonto', kbkonto)
    },
}

const mutations = {
    setKbkontos(state, response) {
        state.kbkontos = response.kbkontos
    },
    setKbkonto(state, response) {
        state.kbkonto = response.kbkonto
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
import axios from "axios";

export function buildFormData(formData, data, parentKey) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
    } else {
        const value = data == null ? '' : data;

        formData.append(parentKey, value);
    }
}

export function commonStoreFetch(commit, url, commitName = null, skip = false) {
    return new Promise((resolve, reject) => {
        axios.get(window.apiUrl + url, {'skip_loading': skip})
            .then(response => {
                if (commitName) {
                    commit(commitName, response.data)
                }

                resolve(response)
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.errors) {
                    reject(error.response.data.errors)
                }
            })
    })
}

export function commonTableFetch(commit, url, commitName = null, params, additionalParams = {}) {
    return new Promise((resolve) => {
        const CancelToken = window.axios.CancelToken
        const cancelTokenSource = CancelToken.source()

        window.store.commit('setFetchToken', cancelTokenSource)
        if (typeof params == "object") {
            let queryString = Object.keys(params.filter).map((key) => {
                if (Array.isArray(params.filter[key])) {
                    return params.filter[key].map(option => {
                        return 'filter[' + encodeURIComponent(key) + '][]=' + option.id
                    }).join('&')
                } else {
                    return 'filter[' + encodeURIComponent(key) + ']=' + encodeURIComponent(params.filter[key])
                }
            }).join('&');

            queryString += '&' + Object.keys(additionalParams).map(key => {
                return key + '=' + additionalParams[key]
            }).join('&')
            window.axios.get(window.apiUrl
                + url
                + '?page=' + params.currentPage
                + '&sorting_by=' + params.sortBy
                + '&sorting_desc=' + params.sortDesc
                + '&per_page=' + params.perPage
                + '&format=' + (params.format || null)
                + '&' + queryString
                , {
                    'skip_loading': true,
                    cancelToken: cancelTokenSource.token
                }).then(response => {
                if (commitName) {
                    if (response) {
                        commit(commitName, response.data)
                    }
                }
                resolve()
            })
        }
    })
}

export function commonStoreSave(url, data, skip = true) {
    return new Promise((resolve, reject) => {
        window.axios.post(window.apiUrl + url, data, {'skip_loading': skip})
            .then(response => {
                resolve(response)
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.errors) {
                    reject(error.response.data.errors)
                }
            })
    })
}

export function commonStoreUpdate(url, data, skip = true) {
    return new Promise((resolve, reject) => {
        window.axios.put(window.apiUrl + url, data, {'skip_loading': skip})
            .then(response => {
                resolve(response)
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.errors) {
                    reject(error.response.data.errors)
                }
            })
    })
}

export function commonStoreDelete(url) {
    return new Promise((resolve) => {
        axios.delete(window.apiUrl + url).then(response => {
            resolve(response)
        })
    })
}
<template>
    <div>
        <template v-if="field.type=='text'">
            <zw-input-group v-model="inputVal"
                            :name="'custom.'+field.model+'.'+field.name"
                            :label="field.options.label ? field.options.label : field.name"
                            :placeholder="field.options.placeholder ? field.options.placeholder : ' '"
                            :readonly="readonly"
                            :validate="{required:isRequired}"
                            :size="size"
                            :disable-label="field.options.disable_label ? true : false"
                            @change="(val)=>{changed('custom.'+field.model+'.'+field.name, val)}"
            ></zw-input-group>
        </template>
        <template v-else-if="field.type=='date'">
            <zw-new-date-group v-model="inputVal"
                               :name="'custom.'+field.model+'.'+field.name"
                               :label="field.options.label ? field.options.label : field.name"
                               :placeholder="field.options.placeholder ? field.options.placeholder : ' '"
                               :readonly="readonly"
                               :format="format"
                               :minDate="minDate"
                               :validate="{required:isRequired}"
                               :disable-label="field.options.disable_label ? true : false"
                               :size="size"
                               @change="(val)=>{changed('custom.'+field.model+'.'+field.name, val)}"
            ></zw-new-date-group>
        </template>
        <template v-else-if="field.type=='textarea'">
            <zw-textarea-group v-model="inputVal"
                               :name="'custom.'+field.model+'.'+field.name"
                               :label="field.options.label ? field.options.label : field.name"
                               :placeholder="field.options.placeholder ? field.options.placeholder : ' '"
                               :readonly="readonly"
                               :validate="{required:isRequired}"
                               :size="size"
                               :disable-label="field.options.disable_label ? true : false"
                               @change="(val)=>{changed('custom.'+field.model+'.'+field.name, val)}"
            ></zw-textarea-group>
        </template>
        <template v-else-if="field.type=='number'">
            <zw-input-group v-model="inputVal"
                            type="number"
                            :name="'custom.'+field.model+'.'+field.name"
                            :label="field.options.label ? field.options.label : field.name"
                            :placeholder="field.options.placeholder ? field.options.placeholder : ' '"
                            :readonly="readonly"
                            :validate="{required:isRequired}"
                            :size="size"
                            :disable-label="field.options.disable_label ? true : false"
                            @change="(val)=>{changed('custom.'+field.model+'.'+field.name, val)}"
            ></zw-input-group>
        </template>
        <template v-else-if="field.type=='select'">
            <template v-if="field.options.multiselect=='1'">
                <b-form-group>
                    <template v-if="field.options.disable_label ? false : true" v-slot:label>
                        {{ field.options.label ? field.options.label : field.name }}
                    </template>
                    <multiselect v-model="inputVal"
                                 :name="'custom.'+field.model+'.'+field.name"
                                 :placeholder="field.options.placeholder ? field.options.placeholder : ' '"
                                 :searchable="true"
                                 :multiple="true"
                                 :validate="{required:isRequired}"
                                 :options="Object.keys(field.options.options)"
                                 :size="size"
                                 select-label=""
                                 @change="(val)=>{changed('custom.'+field.model+'.'+field.name, val)}"
                                 :custom-label="opt => field.options.options[opt]"
                    ></multiselect>
                </b-form-group>
            </template>
            <template v-else>
                <zw-select-group v-model="inputVal"
                                 :name="'custom.'+field.model+'.'+field.name"
                                 :label="field.options.label ? field.options.label : field.name"
                                 :placeholder="field.options.placeholder ? field.options.placeholder : ' '"
                                 :options="field.options.options | optionsWithKeys(true)"
                                 :validate="{required:isRequired}"
                                 :disable-label="field.options.disable_label ? true : false"
                                 :size="size"
                                 @change="(val)=>{changed('custom.'+field.model+'.'+field.name, val)}"
                ></zw-select-group>
            </template>
        </template>
        <template v-else-if="field.type=='radio'">
            <b-form-group
                :label="field.options.disable_label ? null : (field.options.label ? field.options.label : field.name)">
                <b-form-radio v-for="(option,key) in field.options.options"
                              :key="key"
                              v-model="inputVal"
                              :name="'custom.'+field.model+'.'+field.name+id"
                              :value="key"
                              :inline="true"
                              :validate="{required:isRequired}"
                              :size="size"
                              @change="(val)=>{changed('custom.'+field.model+'.'+field.name, val)}"
                >{{ option }}
                </b-form-radio>
            </b-form-group>
        </template>
        <template v-else-if="field.type=='boolean'">
            <zw-select-group v-model="inputVal"
                             :name="'custom.'+field.model+'.'+field.name"
                             :label="field.options.label ? field.options.label : field.name"
                             :placeholder="field.options.placeholder ? field.options.placeholder : ' '"
                             :validate="{required:isRequired}"
                             @change="(val)=>{changed('custom.'+field.model+'.'+field.name, val)}"
                             :disable-label="field.options.disable_label ? true : false"
                             :size="size"
                             :options="{
                                            0: $t('common.button.no'),
                                            1: $t('common.button.yes'),
                             }"
            ></zw-select-group>
        </template>
        <template v-else-if="field.type=='wysiwyg'">
            <zw-ckeditor v-model="inputVal"
                         mode="simple"
                         :name="'custom.'+field.model+'.'+field.name"
                         :label="field.options.label ? field.options.label : field.name"
                         :placeholder="field.options.placeholder ? field.options.placeholder : ' '"
                         :validate="{required:isRequired}"
                         @change="(val)=>{changed('custom.'+field.model+'.'+field.name, val)}"
                         :disable-label="field.options.disable_label ? true : false"
            ></zw-ckeditor>
        </template>
        <template v-else>This custom field type not implemented yet!</template>
    </div>
</template>

<script>
import ZwNewDateGroup from "./ZwNewDateGroup";

export default {
    components: {ZwNewDateGroup},
    props: {
        'field': {
            type: Object,
            required: true
        },
        'model': {
            type: Object,
            required: false
        },
        'value': [String, Number, Array],
        'id': [String, Number],
        'readonly': {
            type: Boolean,
            default: false
        },
        'isRequired': {
            type: Boolean,
            default: false,
        },
        'minDate': {
            type: String,
            default: ''
        },
        'format': {
            type: String
        },
        'size': {
            type: String
        }
    },
    methods: {
        changed(name, val) {
            this.$emit('change', this.inputVal)

            let id = this.model ? this.model.id : null
            this.$root.$emit('custom_field_changed', {name, value: val, id: id})
        },
        isJsonString(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        }
    },
    computed: {
        inputVal: {
            get() {
                if(this.field.options.multiselect=='1' && typeof this.value != 'object') {
                    if(this.isJsonString(this.value)) {
                        return JSON.parse(this.value)
                    } else {
                        return [this.value]
                    }

                }
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
                // this.$emit('update:'+this.field.name, val);
            }
        }
    },
}
</script>
<template>
    <validation-provider :name="name" :rules="validate" v-slot="validationContext">
        <b-form-group>
            <template v-if="!disableLabel" v-slot:label>
                <template v-if="label">{{ label }}</template>
                <template v-else-if="labelHtml"><span v-html="labelHtml"></span></template>
                <template v-else>
                    {{ $t(labelPrefix + name) }}
                </template>
                <span v-if="validationContext.required" class="text-danger">*</span>
            </template>

            <b-input-group>
                <b-input-group-prepend v-if="icon" is-text>
                    <font-awesome-icon :icon="icon"/>
                </b-input-group-prepend>
                <b-form-checkbox-group
                    v-model="inputVal"
                    :options="options"
                    :text-field="textField"
                    :value-field="valueField"
                    stacked
                    :size="size"
                ></b-form-checkbox-group>
            </b-input-group>
            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
    </validation-provider>
</template>

<script>
import validateMixin from '../mixins/validate'

export default {
    props: {
        'size': {
            type: String,
            default: 'lg'
        },
        'value': {},
        'form': {},
        'name': {},
        'validate': {},
        'icon': {},
        'options': {},
        'textField': {},
        'valueField': {},
        'label': String,
        'labelHtml': String,
        'disableLabel': {
            type: Boolean,
            default: false
        },
        'labelPrefix': {
            type: String,
            default: 'common.label.'
        },
    },
    mixins: [validateMixin],
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        }
    },
}
</script>
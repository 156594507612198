import {commonStoreFetch} from "@/store/common-functions";

function getDefaultState() {
    return {
        modelColumns: [],
        allModelColumns: [],
        helpData: '',
        commonData: {
            domain: '',
            user: {},
            modules: [],
            groups: [],
            salutations: [],
            formality: [],
            countries: [],
            offeringPeriods: [],
            addressTypes: [],
            units: [],
            positionTypes: [],
            weightUnits: [],
            vats: [],
            todoStatuses: [],
            massMethods: [],
            todoActions: [],
            eventsDateOptions: [],
            listTypes: [],
            settings: [],
            statuses: {},
            fulfilmentStatuses: {},
            shippingStatuses: {},
            customerStatuses: {},
            attributes: {},
            customerTypes: [],
            customerForms: [],
            sources: [],
            sourceTypes: [],
            entityTypes: [],
            defaultColors: [],
            entityStatusColors: [],
            statusColors: [],
            documentTypes: [],
            documentsColumns: [],
            invoiceTypes: [],
            locales: [],
            documents: [],
            infoBlocks: [],
            addressFields: [],
            dhlLabelFormats: [],
            barkonto: [],
            kb_text: [],
            rechnungskonto: [],
            emailTemplates: [],
            permissions: [],
            space: {},
            documentLayouts: {},
            plugins: [],
            plugins_css: [],
            entity_statuses: [],
            offering_date_fields: [],
            last_app_version: null,
            impersonated: false,
            simple_mode: false,
            delete_types: [],
            workflow_trigger_types: [],
            workflow_triggers: [],
            all_workflow_triggers: [],
            getCustomerTags: [],
        },
    }
}

const getters = {
    getModelColumns: state => state.modelColumns,
    getAllModelColumns: state => state.allModelColumns,
    getHelpData: state => state.helpData,
    getModules(state) {
        return state.commonData.modules
    },
    getCommonData(state) {
        return state.commonData
    },
    getGroups(state) {
        return state.commonData.groups
    },
    getSalutations(state) {
        return state.commonData.salutations
    },
    getFormality(state) {
        return state.commonData.formality
    },
    getPrefixes(state) {
        return state.commonData.prefixes
    },
    getSex(state) {
        return state.commonData.sex
    },
    getCotravelerStatus(state) {
        return state.commonData.cotravelerStatus
    },
    getCountries(state) {
        return state.commonData.countries
    },
    getPriceLists(state) {
        return state.commonData.priceLists
    },
    getOfferingPeriods(state) {
        return state.commonData.offeringPeriods
    },
    getAddressTypes(state) {
        return state.commonData.addressTypes
    },
    getUnits(state) {
        return state.commonData.units
    },
    getPositionTypes(state) {
        return state.commonData.positionTypes
    },
    getWeightUnits(state) {
        return state.commonData.weightUnits
    },
    getVats(state) {
        return state.commonData.vats
    },
    getDefaultVat(state) {
        return state.commonData.defaultVat
    },
    getDiscountTypes(state) {
        return state.commonData.discountTypes
    },
    getDiscountTargets(state) {
        return state.commonData.discountTargets
    },
    getTodoStatues(state) {
        return state.commonData.todoStatuses
    },
    getTodoActions(state) {
        return state.commonData.todoActions
    },
    getTodoSubActions(state) {
        return state.commonData.todoSubActions
    },
    getEventsDateOptions(state) {
        return state.commonData.eventsDateOptions
    },
    getListTypes(state) {
        return state.commonData.listTypes
    },
    getSettings(state) {
        return state.commonData.settings
    },
    getStatuses(state) {
        return state.commonData.statuses
    },
    getFulfilmentStatuses(state) {
        return state.commonData.fulfilmentStatuses
    },
    getShippingStatuses(state) {
        return state.commonData.shippingStatuses
    },
    getEntityStatuses(state) {
        return state.commonData.entity_statuses
    },
    getMassMethods(state) {
        return state.commonData.massMethods
    },
    getAttributes(state) {
        return state.commonData.attributes
    },
    getCustomerTypes(state) {
        return state.commonData.customerTypes
    },
    getCustomerForms(state) {
        return state.commonData.customerForms
    },
    getSources(state) {
        return state.commonData.sources
    },
    getSourceTypes(state) {
        return state.commonData.sourceTypes
    },
    getEntityTypes(state) {
        return state.commonData.entityTypes
    },
    getDefaultColors(state) {
        return state.commonData.defaultColors
    },
    getEntityStatusColors(state) {
        return state.commonData.entityStatusColors
    },
    getStatusColors(state) {
        return state.commonData.statusColors
    },
    getInvoiceTypes(state) {
        return state.commonData.invoiceTypes
    },
    getDocumentTypes(state) {
        return state.commonData.documentTypes
    },
    getLocales(state) {
        return state.commonData.locales
    },
    getDocuments(state) {
        return state.commonData.documents
    },
    getInfoBlocks(state) {
        return state.commonData.infoBlocks
    },
    getAddressFields(state) {
        return state.commonData.addressFields
    },
    getDocumentsColumns(state) {
        return state.commonData.documentsColumns
    },
    getDocumentFormats(state) {
        return state.commonData.documentFormats
    },
    getShippingMethods(state) {
        return state.commonData.shippingMethods
    },
    getDhlLabelFormats(state) {
        return state.commonData.dhlLabelFormats
    },
    getBarkonto(state) {
        return state.commonData.barkonto
    },
    getRechnungskonto(state) {
        return state.commonData.rechnungskonto
    },
    getKbText(state) {
        return state.commonData.kb_text
    },
    getEmailTemplates(state) {
        return state.commonData.emailTemplates
    },
    getPermissions(state) {
        return state.commonData.permissions
    },
    getSpace(state) {
        return state.commonData.space
    },
    getMe(state) {
        return state.commonData.user
    },
    getDocumentLayouts(state) {
        return state.commonData.documentLayouts
    },
    getPlugins(state) {
        return state.commonData.plugins
    },
    getPluginsCss(state) {
        return state.commonData.plugins_css
    },
    getOfferingDateFields(state) {
        return state.commonData.offering_date_fields
    },
    getLastAppVersion(state) {
        return state.commonData.last_app_version
    },
    isImpersonated(state) {
        return state.commonData.impersonated
    },
    isSimpleMode(state) {
        return state.commonData.simple_mode
    },
    getCustomerTags(state) {
        return state.commonData.tags
    },
    getDeleteTypes(state) {
        return state.commonData.delete_types
    },
    getWorkflowTriggerTypes(state) {
        return state.commonData.workflow_trigger_types
    },
    getWorkflowTriggers(state) {
        return state.commonData.workflow_triggers
    },
    getAllWorkflowTriggers(state) {
        return state.commonData.all_workflow_triggers
    }
}

const actions = {
    fetchCommonData({commit}) {
        return new Promise((resolve, reject) => {
            window.axios.get(window.apiUrl + '/common-data', {'skip_loading': true})
                .then(response => {
                    commit('setCommonData', response.data)
                    resolve()
                })
                .catch((error) => {
                    reject(error);
                })
        })
    },
    fetchHelpData({commit, _}, module) {
        return new Promise((resolve, reject) => {
            window.axios.get(window.apiUrl + '/common-help/' + module, {'skip_loading': true})
                .then(response => {
                    commit('setHelpData', response.data)
                    resolve()
                })
                .catch((error) => {
                    reject(error);
                })
        })
    },
    fetchModelColumns({commit, _}, params) {
        return commonStoreFetch(commit, '/get-model-columns/' + params.name, 'setModelColumns')
    },
    fetchAllModelColumns({commit, _}, params) {
        return commonStoreFetch(commit, '/get-all-model-columns/' + params.name, 'setAllModelColumns')
    },
}

const mutations = {
    setCommonData(state, commonData) {
        state.commonData = commonData
    },
    setHelpData(state, helpData) {
        state.helpData = helpData
    },
    setModelColumns(state, data) {
        state.modelColumns = data
    },
    setAllModelColumns(state, data) {
        state.allModelColumns = data
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
import {commonTableFetch} from "@/store/common-functions";

function getDefaultState() {
    return {
        cashInvoicesTable: {},
        cashInvoice: {},
    }
}

const getters = {
    getCashInvoicesTable: state => state.cashInvoicesTable,
    getCashInvoice: state => state.cashInvoice.invoice,
}

const actions = {
    fetchCashInvoices({commit, _}, params) {
        return commonTableFetch(commit, '/cash-invoice', 'setCashInvoicesTable', params)
    },
    fetchCashInvoice({commit}, {id}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/cash-invoice/' + (id ? id : 0), {'skip_loading': true})
                .then(response => {
                    commit('setCashInvoice', response.data)
                    resolve(response)
                })
        })
    },
    // deleteIncomingInvoice({dispatch}, id) {
    //     return new Promise((resolve) => {
    //         window.axios.delete(window.apiUrl + '/incoming-invoices/' + id).then((response) => {
    //             resolve(response);
    //         })
    //     })
    // },
    saveCashInvoice({commit}, cashInvoice) {
        return new Promise((resolve, reject) => {
            window.axios.post(window.apiUrl + '/cash-invoice', cashInvoice, {
                'skip_loading': true
            })
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
}

const mutations = {
    setCashInvoicesTable(state, data) {
        state.cashInvoicesTable = data
    },
    setCashInvoice(state, data) {
        state.cashInvoice = data
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
import {buildFormData, commonTableFetch} from "@/store/common-functions";

function getDefaultState() {
    return {
        contract: {
            id: null,
            customer: '',
            contract: '',
        },
        customerContracts: [],
        contractsTable: {},
        preview: {},
        allFields: {},
    }
}

const getters = {
    getContract: state => state.contract,
    getCustomerContracts: state => state.customerContracts,
    getContractAllFields: state => state.allFields,
    getContractPreview: state => state.preview,
    getContractsTable: state => state.contractsTable,
}

const actions = {
    fetchCustomerContracts({commit, _}, customerId) {
        return new Promise((resolve, reject) => {
            window.axios.get(window.apiUrl + '/customer-contract/customer/' + customerId, {'skip_loading': true})
                .then(response => {
                    commit('setCustomerContracts', response.data)
                    resolve()
                }, () => {
                    reject()
                })
        })
    },
    fetchContract({commit}, id) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/customer-contract/' + (id ? id : 0), {'skip_loading': true})
                .then(response => {
                    commit('setContract', {...response.data, id})
                    resolve(response)
                })
        })
    },
    fetchContractPreview({commit, _}, contract) {
        return new Promise((resolve, reject) => {
            window.axios.post(window.apiUrl + '/customer-contract', contract)
                .then(response => {
                    commit('setContractPreview', response.data)
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error.response.data);
                    reject(error.response.data)
                })
        })
    },
    fetchAllContractFields({commit, _}, data) {
        return new Promise((resolve, reject) => {
            window.axios.post(window.apiUrl + '/customer-contract-all-fields', data)
                .then(response => {
                    commit('setContractAllFields', response.data)
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error.response.data);
                    reject(error.response.data)
                })
        })
    },
    saveContract({commit}, contract) {
        return new Promise((resolve, reject) => {
            if (contract.id) {
                window.axios.put(window.apiUrl + '/customer-contract/' + contract.id, contract, {'skip_loading': true})
                    .then(response => {
                        commit('setContract', response.data)
                        resolve(response);
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        reject(error.response.data)
                    })
            } else {
                window.axios.post(window.apiUrl + '/customer-contract', contract)
                    .then(response => {
                        commit('setContract', response.data)
                        resolve(response);
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        reject(error.response.data)
                    })
            }
        })
    },
    deleteContract({dispatch}, {contractId}) {
        return new Promise((resolve) => {
            window.axios.delete(window.apiUrl + '/customer-contract/' + contractId).then((response) => {
                resolve(response);
            })
        })
    },
    deleteContracts({dispatch}, ids) {
        return new Promise((resolve) => {
            let queryString = ids.map((id) => {
                return 'ids[]=' + id
            }).join('&');

            window.axios.delete(window.apiUrl + '/customer-contract?' + queryString).then((response) => {
                resolve(response);
            })
        })
    },
    fetchContracts({commit, _}, params) {
        return commonTableFetch(commit, '/customer-contract', 'setContractsTable', params)
    },
    fetchSingleContracts({commit, _}, params) {
        return commonTableFetch(commit, '/customer-contract', 'setContractsTable', params, {
            tableName: params.tableName,
        })
    },
    setDocsCustomerContract({commit}, params) {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            buildFormData(formData, params)

            window.axios.post(window.apiUrl + '/customer-contract/save-docs', formData, {
                'skip_loading': true,
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            })
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
}

const mutations = {
    setContract(state, contract) {
        state.contract = {...getDefaultState().contract, ...contract}
    },
    setContractPreview(state, data) {
        state.preview = data
    },
    setContractAllFields(state, data) {
        state.allFields = data
    },
    setCustomerContracts(state, data) {
        state.customerContracts = data
    },
    setContractsTable(state, data) {
        state.contractsTable = data
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
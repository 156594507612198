function getDefaultState() {
    return {
        customerCategory: null,
        customerCategories: [],
    }
}

const getters = {
    getCustomerCategory: state => state.customerCategory,
    getCustomerCategories: state => state.customerCategories,
}

const actions = {
    fetchCustomerCategories({commit}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/customercategories', {'skip_loading': true})
                .then(response => {
                    commit('setCustomerCategories', response.data)
                    resolve(response)
                })
        })
    },
    fetchCustomerCategory({commit}, id) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/customercategories/' + (id ? id : 0), {'skip_loading': true})
                .then(response => {
                    commit('setCustomerCategory', {...response.data, id})
                    resolve(response)
                })
        })
    },
    saveCustomerCategory({commit}, customerCategory) {
        return new Promise((resolve, reject) => {
            window.axios.post(window.apiUrl + '/customercategories/' + (customerCategory.id ? customerCategory.id : 0), customerCategory, {'skip_loading': true})
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
    deleteCustomerCategory({dispatch}, id) {
        return new Promise((resolve) => {
            window.axios.delete(window.apiUrl + '/customercategories/' + id).then((response) => {
                resolve(response);
            })
        })
    },
}

const mutations = {
    setCustomerCategory(state, response) {
        state.customerCategory = response.customerCategory
    },
    setCustomerCategories(state, customerCategories) {
        state.customerCategories = customerCategories.customerCategories
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
import {
    commonStoreFetch,
    commonStoreSave,
    commonStoreDelete
} from "@/store/common-functions";

function getDefaultState() {
    return {
        kbbuchungs: [],
        kbbuchung: {},
    }
}

const getters = {
    getKbbuchungs: state => state.kbbuchungs,
    getKbbuchung: state => state.kbbuchung,
}

const actions = {
    fetchKbbuchungs({commit}) {
        return commonStoreFetch(commit, '/kbbuchung', 'setKbbuchungs')
    },
    fetchKbbuchung({commit}, id) {
        return commonStoreFetch(commit, '/kbbuchung/' + (id ? id : 0), 'setKbbuchung')
    },
    deleteKbbuchung({dispatch}, id) {
        return commonStoreDelete('/kbbuchung/' + id)
    },
    saveKbbuchung({commit}, kbbuchung) {
        return commonStoreSave('/kbbuchung', kbbuchung)
    },
}

const mutations = {
    setKbbuchungs(state, response) {
        state.kbbuchungs = response.kbbuchungs
    },
    setKbbuchung(state, response) {
        state.kbbuchung = response.kbbuchung
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
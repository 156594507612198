function getDefaultState() {
    return {
        vat: null,
        vats: [],
    }
}

const getters = {
    getVat: state => state.vat,
    getVats: state => state.vats,
}

const actions = {
    fetchVats({commit}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/vats', {'skip_loading': true})
                .then(response => {
                    commit('setVats', response.data)
                    resolve(response)
                })
        })
    },
    fetchVat({commit}, id) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/vats/' + (id ? id : 0), {'skip_loading': true})
                .then(response => {
                    commit('setVat', {...response.data, id})
                    resolve(response)
                })
        })
    },
    saveVat({commit}, vat) {
        return new Promise((resolve, reject) => {
            window.axios.post(window.apiUrl + '/vats/' + (vat.id ? vat.id : 0), vat, {'skip_loading': true})
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
    deleteVat({dispatch}, id) {
        return new Promise((resolve) => {
            window.axios.delete(window.apiUrl + '/vats/' + id).then((response) => {
                resolve(response);
            })
        })
    },
}

const mutations = {
    setVat(state, response) {
        state.vat = response.vat
    },
    setVats(state, vats) {
        state.vats = vats.vats
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
<template>
    <li class="c-sidebar-nav-item">
        <slot>
            <router-link
                :class="linkClasses"
                :to="computedLinkProps.to || computedLinkProps.href"
                @mousedown.native="click(computedLinkProps.to || computedLinkProps.href)"
            >
                <font-awesome-icon v-if="fontIcon" :class="['c-sidebar-nav-icon']" :icon="['fa', fontIcon]"/>
                <CIcon v-if="icon" v-bind="computedIcon"/>
                {{ name }}
                <CBadge
                    v-if="badge"
                    v-bind="Object.assign({}, badge, { text: null })"
                >
                    {{ badge.text }}
                </CBadge>
            </router-link>
        </slot>
    </li>
</template>

<script>
import CLink, {props as linkProps} from '@coreui/vue/src/components/link/CLink'
import CBadge from '@coreui/vue/src/components/badge/CBadge'
import CIcon from '@coreui/icons-vue/src/CIconRaw.vue'

const props = Object.assign(
    {},
    linkProps,
    {
        exact: {
            type: Boolean,
            default: true
        },
        activeClass: {
            type: String,
            default: 'c-active'
        }
    },
    {
        name: String,
        icon: [String, Object],
        fontIcon: String,
        badge: Object,
        addLinkClasses: [String, Array, Object],
        label: Boolean,
        color: String
    }
)

export default {
    name: 'CustomCSidebarNavItem',
    components: {
        CBadge,
        CIcon,
        CLink
    },
    props,
    methods: {
        click(to) {
            if (typeof to == "object") {
                this.$root.$children[0].openModal(to.modal, {}, null, to.options)
            } else {
                if (this.$route.path == to) {
                    // this.$router.go(0)
                    this.$router.push({path: '/refresh', query: {path: to}})
                }
            }
        }
    },
    computed: {
        computedLinkProps() {
            return Object.keys(linkProps).reduce((props, key) => {
                props[key] = this[key]
                return props
            }, {})
        },
        linkClasses() {
            return [
                this.label ? 'c-sidebar-nav-label' : 'c-sidebar-nav-link',
                this.color && `c-sidebar-nav-link-${this.color}`,
                this.addLinkClasses,
                (this.computedLinkProps.to || this.computedLinkProps.href) == this.$route.path && 'c-active'
            ]
        },
        computedIcon() {
            if (typeof this.icon === 'object') {
                const key = this.icon.size ? 'class' : 'customClasses'
                return {
                    ...this.icon,
                    [`${key}`]: this.icon.customClasses ||
                    ['c-sidebar-nav-icon', this.icon.className]
                }
            } else {
                return {customClasses: 'c-sidebar-nav-icon', name: this.icon}
            }
        }
    }
}
</script>


import {commonTableFetch} from "@/store/common-functions";

function getDefaultState() {
    return {
        thenew: {
            id: null,
            title: '',
            description: '',
            link: '',
        },
        thenewsTable: {},
    }
}

const getters = {
    getThenew: state => state.thenew,
    getThenewsTable: state => state.thenewsTable,
}

const actions = {
    fetchThenew({commit}, id) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/news/' + (id ? id : 0), {'skip_loading': true})
                .then(response => {
                    commit('setThenew', {...response.data, id})
                    resolve(response)
                })
        })
    },
    fetchShowThenew({commit}, data) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/show-news/' + data.domain + '/' + data.userId+'/' + data.id ?? 0, {'skip_loading': true})
                .then(response => {
                    commit('setThenew', {...response.data})
                    resolve(response)
                })
        })
    },
    saveThenew({commit}, thenew) {
        return new Promise((resolve, reject) => {
            if (thenew.id) {
                window.axios.put(window.apiUrl + '/news/' + thenew.id, thenew, {'skip_loading': true})
                    .then(response => {
                        commit('setThenew', response.data)
                        resolve(response);
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        reject(error.response.data)
                    })
            } else {
                window.axios.post(window.apiUrl + '/news', thenew)
                    .then(response => {
                        commit('setThenew', response.data)
                        resolve(response);
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        reject(error.response.data)
                    })
            }
        })
    },
    deleteThenew({dispatch}, {thenewId}) {
        return new Promise((resolve) => {
            window.axios.delete(window.apiUrl + '/news/' + thenewId).then((response) => {
                resolve(response);
            })
        })
    },
    deleteThenews({dispatch}, ids) {
        return new Promise((resolve) => {
            let queryString = ids.map((id) => {
                return 'ids[]=' + id
            }).join('&');

            window.axios.delete(window.apiUrl + '/news?' + queryString).then((response) => {
                resolve(response);
            })
        })
    },
    fetchThenews({commit, _}, params) {
        return commonTableFetch(commit, '/news', 'setThenewsTable', params)
    },
}

const mutations = {
    setThenew(state, thenew) {
        state.thenew = {...getDefaultState().thenew, ...thenew}
    },
    setThenewsTable(state, data) {
        state.thenewsTable = data
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
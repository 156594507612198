function getDefaultState() {
    return {
        lagerType: null,
        lagerTypes: [],
        lagerTypesList: [],
        lagerBarcodesPerRowList: [],
    }
}

const getters = {
    getLagerType: state => state.lagerType,
    getLagerTypes: state => state.lagerTypes,
    getLagerTypesList: state => state.lagerTypesList,
    getLagerBarcodesPerRowList: state => state.lagerBarcodesPerRowList,
}

const actions = {
    fetchLagerTypes({commit}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/lagertypes', {'skip_loading': true})
                .then(response => {
                    commit('setLagerTypes', response.data)
                    resolve(response)
                })
        })
    },
    fetchLagerType({commit}, id) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/lagertypes/' + (id ? id : 0), {'skip_loading': true})
                .then(response => {
                    commit('setLagerType', {...response.data, id})
                    resolve(response)
                })
        })
    },
    fetchLagerTypesList({commit}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/lagertypeslist', {'skip_loading': true})
                .then(response => {
                    response.data.data.unshift({"id": 0, "name": ''});
                    commit('setLagerTypesList', response.data)
                    resolve(response)
                })
        })
    },
    fetchBarcodesPerRow({commit}) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/lagerbarcodesperrowlist', {'skip_loading': true})
                .then(response => {
                    commit('setLagerBarcodesPerRowList', response.data)
                    resolve(response)
                })
        })
    },
    saveLagerType({commit}, lagerType) {
        return new Promise((resolve, reject) => {
            window.axios.post(window.apiUrl + '/lagertypes/' + (lagerType.id ? lagerType.id : 0), lagerType, {'skip_loading': true})
                .then(response => {
                    resolve(response);
                })
                .catch((error) => {
                    if (error.response && error.response.data && error.response.data.errors) {
                        reject(error.response.data.errors)
                    }
                })
        })
    },
    deleteLagerType({dispatch}, id) {
        return new Promise((resolve) => {
            window.axios.delete(window.apiUrl + '/lagertypes/' + id).then((response) => {
                resolve(response);
            })
        })
    },
}

const mutations = {
    setLagerType(state, response) {
        state.lagerType = response.lagerType
    },
    setLagerTypes(state, lagerTypes) {
        state.lagerTypes = lagerTypes.lagerTypes
    },
    setLagerTypesList(state, lagerTypesList) {
        state.lagerTypesList = lagerTypesList.data
    },
    setLagerBarcodesPerRowList(state, lagerBarcodesPerRowList) {
        state.lagerBarcodesPerRowList = lagerBarcodesPerRowList.data
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
function getDefaultState() {
    return {
        todo: [],
    }
}

const getters = {
    getTodo: state => state.todo,
}

const actions = {
    fetchTodo({commit, _}, id) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/todo/' + id, {'skip_loading': true})
                .then(response => {
                    commit('setTodo', response.data)
                    resolve()
                })
        })
    },
    saveTodo({commit}, todo) {
        return new Promise((resolve) => {
            if (todo.id) {
                window.axios.put(window.apiUrl + '/todo/' + todo.id, todo, {'skip_loading': true}).then(response => {
                    commit('setTodo', response.data)
                    resolve(response);
                })
            } else {
                window.axios.post(window.apiUrl + '/todo', todo, {'skip_loading': true}).then(response => {
                    commit('setTodo', response.data)
                    resolve(response);
                })
            }
        })
    },
}

const mutations = {
    setTodo(state, todo) {
        state.todo = todo
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
function getDefaultState() {
    return {
        headers: [],
        footers: [],
        placeholders: [],
    }
}

const getters = {
    getHeaders: state => state.headers,
    getFooters: state => state.footers,
    getPlaceholders: state => state.placeholders,
}

const actions = {
    fetchTemplates({commit, _}, type) {
        return new Promise((resolve) => {
            window.axios.get(window.apiUrl + '/templates?type=' + type, {'skip_loading': true})
                .then(response => {
                    commit('setTemplates', response.data)
                    resolve()
                })
        })
    },
}

const mutations = {
    setTemplates(state, templates) {
        state.headers = templates.headers
        state.footers = templates.footers
        state.placeholders = templates.placeholders
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
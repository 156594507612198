<script>
const RemoveConfirmAuth = {
    install(Vue, options) {
        Vue.prototype.$removeConfirmAuth = function (action, payload, callback = null) {
            this.$confirm(
                {
                    auth: true,
                    message: this.$t('common.confirmations.delete_confirm'),
                    button: {
                        no: this.$t('common.button.no'),
                        yes: this.$t('common.button.yes')
                    },
                    callback: (confirm, password) => {
                        if (confirm) {
                            if (password == 'delete') {
                                this.$store.dispatch(action, payload).then((response) => {
                                    if (response.status == 204 || response.status == 200) {
                                        if (response.data.batch_group) {
                                            this.$root.$children[0].showJobsToast(response.data.batch_group)
                                        } else {
                                            this.$root.showMessage(this.$t('common.toasts.delete'), this.$t('common.toasts.deleted'), 'success')
                                        }

                                        if (callback) {
                                            callback(response)
                                        }
                                    }
                                })
                            } else {
                                this.$root.showMessage(this.$t('common.toasts.delete'), this.$t('common.toasts.wrong_password'), 'danger')
                            }

                        }
                    }
                }
            )
        }
    }
};

export default RemoveConfirmAuth;
</script>
import {commonStoreFetch, commonStoreSave, commonTableFetch} from "@/store/common-functions";

function getDefaultState() {
    return {
        debitsTable: {},
        creditsTable: {},
        general: {},
    }
}

const getters = {
    getDebitsTable: state => state.debitsTable,
    getCreditsTable: state => state.creditsTable,
    getGeneral: state => state.general,
}

const actions = {
    fetchDebits({commit, _}, params) {
        return commonTableFetch(commit, '/profit-and-loss/debit', 'setDebitsTable', params)
    },
    fetchCredits({commit, _}, params) {
        return commonTableFetch(commit, '/profit-and-loss/debit', 'setCreditsTable', params)
    },
    fetchGeneral({commit, _}, data) {
        return commonStoreSave('/profit-and-loss/general', data)
    },
}

const mutations = {
    setDebitsTable(state, data) {
        state.debitsTable = data
    },
    setCreditsTable(state, data) {
        state.creditsTable = data
    },
}

export default {
    namespaced: true,
    state: getDefaultState,
    getters,
    actions,
    mutations
}
<template>
    <validation-provider :name="name" :rules="validate" v-slot="validationContext">
        <b-form-group>
            <template v-if="!disableLabel" v-slot:label>
                <template v-if="label">{{ label }}</template>
                <template v-else>
                    {{ $t(labelPrefix + name) }}
                </template>
                <span v-if="validationContext.required" class="text-danger">*</span>
            </template>
            <b-input-group>
                <b-input-group-prepend v-if="icon" is-text>
                    <font-awesome-icon :icon="icon"/>
                </b-input-group-prepend>
                <multiselect v-model="inputVal"
                             :multiple="true"
                             :state="getValidationState(validationContext)"
                             v-bind="$props"
                             :readonly="readonly"
                             select-label=""
                ></multiselect>
            </b-input-group>
            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
        </b-form-group>
    </validation-provider>
</template>

<script>
import validateMixin from '../mixins/validate'

export default {
    name: 'ZwMultiselectGroup',
    props: {
        'name': {
            type: String,
            required: true
        },
        'value': [Array],
        'options': [Array],
        'validate': [String, Object],
        'icon': [String, Array],
        'type': String,
        'label': String,
        'disableLabel': {
            type: Boolean,
            default: false
        },
        'readonly': {
            type: Boolean,
            default: false
        },
        'labelPrefix': {
            type: String,
            default: 'common.label.'
        },
    },
    mixins: [validateMixin],
    computed: {
        inputVal: {
            get() {
                const selected = this.options.filter((option, index) => {
                    return this.value.includes(index + '')
                })

                return selected
            },
            set(val) {
                const selected = val.map(option => {
                    return this.options.indexOf(option) + ''
                })

                this.$emit('input', selected);
            }
        }
    },
}
</script>